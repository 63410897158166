import{
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const usersAdapter = createEntityAdapter({})

const initialState = usersAdapter.getInitialState()

export const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder =>({
        getUsers: builder.query({
            query: ()=> '/users',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            keepUnusedDataFor: 5,
            transformResponse: responseData =>{
                const loadedUsers = responseData.map(user => {
                    user.id = user._id
                    return user
                });
                return usersAdapter.setAll(initialState, loadedUsers)
            },
            providesTags: (result, error, arg) =>{
                if(result?.ids){
                    return [
                        {type: 'User', id: 'List'},
                        ...result.ids.map(id=> ({type: 'User', id}))
                    ]
                }else return [{type: 'User', id: 'LIST'}]
            }

        }),
        createUser: builder.mutation({
            query: credentials => ({
                url: '/users',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        editUser: builder.mutation({
            query: credentials => ({
                url: '/users',
                method: 'PATCH',
                body: { ...credentials }
            })
        }),
        deleteUser: builder.mutation({
            query: credentials => ({
                url: '/users',
                method: 'DELETE',
                body: { ...credentials }
            })
        }),
        
    })
})
 export const {
    useGetUsersQuery,
    useCreateUserMutation,
    useEditUserMutation,
    useDeleteUserMutation,
    
 } = userApiSlice

 export const selectUsersResult =  userApiSlice.endpoints.getUsers.select()

 const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data
 )

 export const {
    selectAll: selectAllUsers,
    selectById: selectUsersById,
    selectIds: selectUserIds
 } = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState)

 export const selectUsersByEmail = createSelector(
    selectAllUsers,
    (_, email) => email,
    (users, email) => users.filter(user => user.email === email)
  );