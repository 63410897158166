import { getDraftno, setDraftno } from "../../global";
import { selectDraftsById } from "./DraftApiSlice";
import { useSelector } from "react-redux";
import { useAddnew } from "../admins/adminHooks";
import { useNavigate } from "react-router-dom"
import Loader from '../../components/Loader'
import { useDeleteDraftMutation } from "./DraftApiSlice";
import { useState, useEffect, useRef } from 'react'
import Modal from "../../components/modal";
import NewProposal from "../../components/modal/newProposal";

const Draft = ({ userId, onRemove  }) => {
    const draft = useSelector((state) => selectDraftsById(state, userId));
    const navigate = useNavigate();
    const [openAddnew, isEdit, onAddNew] = useAddnew();
    const [deleteDraft, {isLoading}]= useDeleteDraftMutation()
    let _id
    const [openCreateNew, setOpenCreateNew] = useState(false);

    const onCraeteNewProposal = () => setOpenCreateNew((open) => !open);

    const errRef = useRef()
    const [errMsg, setErrMsg]= useState('')
    const onDeleteDraftClick = async() =>{
        try{
            const _id= draft._id
            const Deleted = await deleteDraft({_id}).unwrap()
            window.location.reload();
      
     
      
    }catch(err) {
      if(!err.status){
         setErrMsg('No Server Response')

      }else if(err.status === 400){
         setErrMsg('All Fields Required!')
      } else if(err.status === 401){
         setErrMsg('Unauthorized')
      }
      else if(err.status === 409){
        setErrMsg('Already exists!')
     } else{
         setErrMsg(err.data?.message);
      }
      errRef.current.focus();
    }
    }
    const onEdit = (e) =>{
        navigate(`./${draft.proposalId}`)
    } 
    useEffect(()=>{
        errRef.current.focus()
      }, [])
    
    if (draft) {
        const handleEdit = () => navigate("/draft");
        const created = new Date(draft.createdAt).toLocaleString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        const updated = new Date(draft.updatedAt).toLocaleString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        //const userRolesString = user.roles.toString().replaceAll(',',',')
        let status = "Active";
        if (draft.status === false) {
          status = "Deactive";
        }
        const cellStatus = draft.active ? "moments ago" : "inactive";
        _id=draft._id
        return (
            <>
              <p ref={errRef} className="" aria-live="assertive">{errMsg}</p>
              <tr className="bg-gray-200 custom-mt-30" >
                <td className="py-2 rounded-s-lg w-1/5 min-w-[30px] text-center">{getDraftno()}</td>
                <td className="w-1/5 min-w-[150px]">{draft.clients_name}</td>
                <td className="w-1/5 min-w-[150px]">{draft.clients_email}</td>
                <td className="w-1/5 min-w-[150px]">{draft.clients_phone}</td>
                <td className="w-1/5 min-w-[150px]">{draft.clients_GST}</td>
                <td className="w-1/5 min-w-[150px]">{created}</td>
                <td className="rounded-e-lg w-1/5 min-w-[150px] text-center">
                      <button
                        className="relative underline mr-4 italic text-sm hover:text-yellow-400 transition-colors duration-300 group"
                        onClick={onEdit}
                      >
                       <span class="material-symbols-outlined">edit</span>
                      {/*  <span className="hidden group-hover:inline-block absolute bottom-full left-1/2 transform -translate-x-1/2 p-2 bg-yellow-400 text-white text-xs rounded">
                        Edit
                      </span> */}
                      </button>
                      <button
                        className="relative underline text-black italic text-sm hover:text-red-600 transition-colors duration-300 group"
                        onClick={onDeleteDraftClick}
                      >
                        <span className="material-symbols-outlined">delete_forever</span>
                        {/* <span className="hidden group-hover:inline-block absolute bottom-full left-1/2 transform -translate-x-1/2 p-2 bg-red-600 text-white text-xs rounded">
                    Delete
                  </span> */}
                      </button>
                    </td>
              </tr>
              <Modal isOpen={openCreateNew} onOutsideClick={onCraeteNewProposal}>
                <NewProposal onCancel={onCraeteNewProposal} client_name={draft.clients_name} client_email={draft.clients_email} client_GST={draft.clients_GST} client_phone={draft.clients_phone} topis={draft.topic}  sum={draft.summary}/>
              </Modal>
              
            </>
          );
    } else return null;
        
    
    
};

export default Draft;
