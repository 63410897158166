
const ProposalInfo = ({ onClose, client_name, client_phone, client_email, client_GST, proposal }) => {
  return (
    <div className="w-[200%] max-w-[90%] mx-auto sm:max-w-2xl lg:max-w-3xl p-6 rounded-3xl bg-white shadow-xl">
      <h1 className="font-bold text-2xl text-center mb-4 relative uppercase">
        Your Proposal{" "}
        <button className="absolute right-1 font-normal" onClick={onClose}>
          <span className="material-symbols-outlined text-red-600">close</span>
        </button>
      </h1>

      <div>
        <p className="flex flex-col sm:flex-row items-start mb-4">
          <span className="sm:w-1/5 font-bold">Client's Name:</span>{" "}
          <span>{client_name}</span>
        </p>

        <p className="flex flex-col sm:flex-row iitems-start mb-4">
          <span className="sm:w-1/5 font-bold">Client's Phone:</span>{" "}
          <span>{client_phone}</span>
        </p>

        <p className="flex flex-col sm:flex-row items-start mb-4">
          <span className="sm:w-1/5 font-bold">Client's Email:</span>
          <span>{client_email}</span>
        </p>

        <p className="flex flex-col sm:flex-row items-startr mb-4">
          <span className="sm:w-1/5 font-bold">Client's GST:</span>{" "}
          <span>{client_GST}</span>
        </p>

        <p className="flex flex-col sm:flex-row items-startr mb-4">
          <span className="sm:w-[45%] font-bold">Proposal:</span>{" "}
          <span>
           {proposal}
          </span>
        </p>
      </div>
    </div>
  );
};

export default ProposalInfo;
