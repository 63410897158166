import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useEditDraftMutation } from '../../../features/drafts/DraftApiSlice';
import { useGetDraftsQuery } from '../../../features/drafts/DraftApiSlice';
import { selectDraftsByProposalId } from '../../../features/drafts/DraftApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';

const Cover = ({slideAnimation, submission, setSubmission}) => {
  
  const [proposalTitle, setProposalTitle] = useState('Proposal E-Learning');
  const [clientHello, setHello] = useState('Namaste')
  const date = new Date().toLocaleString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const [proposalDate, setProposalDate] = useState(date);
  const [fromAddress, setFromAddress] = useState({
    name: 'Daisy Roberts',
    street: 'Director - Global Sales',
    postalCode: 'A1B, Sector 16',
    city: 'Noida. UP - 201301',
    country: 'India',
  });
  const { id } = useParams();

  const proposalId = id
  const {
    data: drafts,
    isSuccess,
    isError,
    error
  } = useGetDraftsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = drafts
    
  }
  console.log(id)

  const draft = useSelector((state) => selectDraftsByProposalId(state, proposalId));

  if(draft){
    console.log(draft)
  }
  

  const [toAddress, setToAddress] = useState({
    name: 'Example Company',
    street: 'Empire street 123',
    postalCode: '254512',
    city: 'New York',
    country: 'USA',
  });
  const [digitalProposal, setDigitalProposal] = useState(
    `After thorough analysis of your unique requirements and objectives, we have meticulously crafted a business proposal that aligns perfectly with your vision. Our proposal encapsulates innovative strategies, actionable insights, and a roadmap to drive growth and elevate your market presence. Request to please go through and leave your questions if any. Thanks!
    `
  );
  const [validity, setValidity] = useState('This proposal is valid till Sep 26th 2023.');
  const [createDraft, {isLoading}]= useEditDraftMutation()
  const [save, setSaved]= useState('Save')
  useEffect(() => {
    const currentDate = new Date();
    const formattedProposalDate = currentDate.toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    setProposalDate(formattedProposalDate);

    const expirationDate = new Date(currentDate);
    expirationDate.setDate(currentDate.getDate() + 21); // Add 21 days to the current date
    const formattedExpirationDate = expirationDate.toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });

    setValidity(`This proposal is valid till ${formattedExpirationDate}.`);
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      let coverData = {
          proposalTitle,
          proposalDate,
          fromAddress,
          toAddress,
          digitalProposal,
          validity,
        }
      
      await createDraft({proposalId, coverData}).unwrap();
      console.log('Draft created successfully');
      setSaved('Saved!')
      window.location.reload()
    } catch (error) {
      console.error('Error creating draft:', error);
    }
  };

  // Function to handle beforeunload event
  const handleBeforeUnload = (e) => {
    // Call the handleSubmit function here
    if(proposalTitle!="Proposal E-Learning"){
        handleSubmit(e);
    }
    // Clear the prompt message (modern browsers ignore the text)
    delete e.returnValue;
  };

  const handleVisibilityChange = (e) => {
    if (document.visibilityState === 'hidden') {
      // Call the handleSubmit function here
      handleSubmit(e);
    }
  };

  /* useEffect(() => {
    // Add the event listeners when the component mounts
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listeners when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); */

  useEffect(() => {
    if (draft) {
      if(draft.length > 0 && draft[0].clients_name){
        console.log("draft:", draft);
        setHello(`Namasthe ${draft[0].clients_name}!`)
        }
      if (draft.length > 0 && draft[0].coverData) {
        
        // Access the clientName property only if introductionData exists
        setProposalTitle(draft[0].coverData.proposalTitle);
        setProposalDate(draft[0].coverData.proposalDate);
        setFromAddress(draft[0].coverData.fromAddress);
        setToAddress(draft[0].coverData.toAddress);
        setDigitalProposal(draft[0].coverData.digitalProposal);
        setValidity(draft[0].coverData.validity);
      }
    }
  }, [draft]);
  
  return (
    <div id="cover-pdf-content" className={`${slideAnimation}`}>
      <div className="relative z-10"> {/* Increase the z-index to a higher value */}
         <button
           className="bg-blue-300 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 hover:text-white transition-colors duration-300 absolute top-0 right-0"
           onClick={handleSubmit}
           disabled={save === 'Saved!'}
         >
          {save}
         </button>
      </div>
      <div className={styles.imgContainer}>
        
        <div className="w-[90%] md:w-96 md:ml-20 mx-auto bg-gray-500 bg-opacity-80 p-8 text-white">
          <h1
            className="text-3xl font-bold"
            contentEditable
            onBlur={(e) => setProposalTitle(e.target.textContent)}
            suppressContentEditableWarning={true}
          >
            {proposalTitle}
          </h1>
          <p
            className="text-sm mt-4"
            contentEditable
            onBlur={(e) => setProposalDate(e.target.textContent)}
            suppressContentEditableWarning={true}
          >
            {proposalDate}
          </p>
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className="md:w-[20%]  pt-2 px-6">
          <h3 className="font-semibold text-lg">From:</h3>
          <div className="text-sm">
            <p
              contentEditable
              onBlur={(e) => setFromAddress({ ...fromAddress, name: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {fromAddress.name}
            </p>
            <p
              contentEditable
              onBlur={(e) => setFromAddress({ ...fromAddress, street: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {fromAddress.street}
            </p>
            <p
              contentEditable
              onBlur={(e) => setFromAddress({ ...fromAddress, postalCode: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {fromAddress.postalCode}
            </p>
            <p
              contentEditable
              onBlur={(e) => setFromAddress({ ...fromAddress, city: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {fromAddress.city}
            </p>
            <p
              contentEditable
              onBlur={(e) => setFromAddress({ ...fromAddress, country: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {fromAddress.country}
            </p>
          </div>
        </div>
        <div className="md:w-[20%]  pt-2 px-6">
          <h3 className="font-semibold text-lg">Attn:</h3>
          <div className="text-sm">
            <p
              contentEditable
              onBlur={(e) => setToAddress({ ...toAddress, name: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {toAddress.name}
            </p>
            <p
              contentEditable
              onBlur={(e) => setToAddress({ ...toAddress, street: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {toAddress.street}
            </p>
            <p
              contentEditable
              onBlur={(e) => setToAddress({ ...toAddress, postalCode: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {toAddress.postalCode}
            </p>
            <p
              contentEditable
              onBlur={(e) => setToAddress({ ...toAddress, city: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {toAddress.city}
            </p>
            <p
              contentEditable
              onBlur={(e) => setToAddress({ ...toAddress, country: e.target.textContent })}
              suppressContentEditableWarning={true}
            >
              {toAddress.country}
            </p>
          </div>
        </div>
        <div className="md:w-[60%]  pt-2 px-6">
          <h3 className="font-semibold text-lg">{clientHello}</h3>
          <p
            className="text-sm"
            contentEditable
            onBlur={(e) => setDigitalProposal(e.target.textContent)}
            suppressContentEditableWarning={true}
          >
            {digitalProposal}
          </p>
        </div>
      </div>
      <div>
     
      </div>
      
      <div>
        <p
          className="p-2 bg-black text-white text-left text-sm"
          style={{ paddingLeft: '20px', paddingRight: '10px' }}
          contentEditable
          onBlur={(e) => setValidity(e.target.textContent)}
          suppressContentEditableWarning={true}
        >
          {validity}
        </p>
        
      </div>
     
    </div>
  );
};

const styles = {
  imgContainer:
    "flex justify-start items-center h-[450px] overflow-hidden relative bg-[url('/public/assets/images/backgroundImage.jpg')] bg-no-repeat bg-cover",
  textContainer: "flex flex-col md:flex-row min-h-72 bg-white py-8",
};

export default Cover;
