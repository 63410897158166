import { useContext, useEffect, useState } from "react";
import ProposalSlide from "../../../components/proposalSlide";
import ContentLayout from "../../../components/contentLayout";
import { AppContext } from "../../../context/store";
import Cover from "../../../components/slides/cover";
import Introduction from "../../../components/slides/introduction";
import Investment from "../../../components/slides/investment";
import Proposal from "../../../components/slides/proposal";
import PlanOfAction from "../../../components/slides/planOfAction";
import AboutUs from "../../../components/slides/aboutUs";
import Contact from "../../../components/slides/contact";
import Reviews from "../../../components/slides/reviews";
import Impression from "../../../components/slides/impression";
import CorporateVideo from "../../../components/slides/corporateVideo";
import Closing from "../../../components/slides/closing";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const ProposalsSlidesPage = () => {
  const Slides = {
    cover: Cover,
    introduction: Introduction,
    "requirement analysis": Proposal,
    "development approach": PlanOfAction,
    "development commercials": Investment,
    contact: Contact,
    reviews: Reviews,
   
  };

  const { selectedSlide, slidesName } = useContext(AppContext);
  const [index, setIndex] = useState(0);
  const [SelectedSlide, setSelectedSlide] = selectedSlide;
  const [animation, setAnimation] = useState ('animate-move-in-right')

  const Slide = Slides[SelectedSlide];
  

  const onNext = () => {
    const next = index + 1;
    setIndex(next < slidesName.length ? next : 0);
    setAnimation('animate-move-in-right');
  };

  const onPrev = () => {
    const prev = index - 1;
    setIndex(prev >= 0 ? prev : slidesName.length - 1);
    setAnimation('animate-move-in-left');
  };

  useEffect(() => setSelectedSlide(slidesName[index]), [index]);

  const handleConvertToPdf = async () => {
    const pdf = new jsPDF({
      orientation: 'landscape', // Set orientation to landscape for a presentation page
      format: 'a4', // You can change the format to other predefined formats as well
    });
  
    const pages = [
      'investment-pdf-content', // Add the Investment slide ID here
    ];
  
    for (const pageId of pages) {
      const pageContent = document.getElementById(pageId);
      const canvas = await html2canvas(pageContent, { scrollY: -window.scrollY });
      const imageData = canvas.toDataURL('image/png');
      pdf.addImage(imageData, 'PNG', 0, 0, 290, 210); // Adjust the positioning and size for 16:9 aspect ratio
      pdf.addPage();
    }
  
    pdf.deletePage(pages.length + 1); // Remove the last blank page
    pdf.save('proposals_slides.pdf');
  };

  return (
    <ContentLayout>
     
      <ProposalSlide onNext={onNext} onPrev={onPrev}>
         <Slide slideAnimation={animation} />
      </ProposalSlide>
    </ContentLayout>
  );
};

export default ProposalsSlidesPage;
