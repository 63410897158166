import { useCreateNewProposalMutation } from "../../../features/proposals/ProposalsApiSlice";
import { useCreateNewDraftMutation } from "../../../features/drafts/DraftApiSlice";
import { useRef, useState, useEffect } from "react";
import { selectCurrentToken } from "../../../features/auth/authSlice"
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { selectUsersByEmail } from "../../../features/users/userApiSlice";
import { useGetUsersQuery } from "../../../features/users/userApiSlice";
import { setProposalno } from "../../../global";


const generateProposalId = () => {
  // Generate a timestamp to ensure uniqueness
  const timestamp = Date.now();
  

  // Generate a random string using Math.random() and convert it to base 36 (alphanumeric)
  const randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  // Concatenate the timestamp and random string to create the proposalId
  const proposalId = `${timestamp}-${randomString}`;

  return proposalId;
};

const NewProposal = ({onCancel, proposalDetails }) => {
  console.log(proposalDetails, "THIS IS")
  const {
    data: clients,
    isLoading: isClientsLoading,
    isSuccess: isClientsSuccess,
    isError: isClientsError,
    error: clientsError
  } = useGetUsersQuery();

  const[ ids, setIDs] = useState()
  const [clone, setClone]= useState('Create New Proposal')
 
  setProposalno()

  console.log("IDS", ids)
  const navigate = useNavigate()
  const userRef = useRef();
  const errRef = useRef()
  const [clients_name, setName] = useState('')
  const [clients_email, setEmail] = useState('')
  const [clients_phone, setPhone] = useState('')
  const [clients_GST, setGST] = useState('')
  const [topic, setTopic] = useState('')
  const proposalId = generateProposalId()
  const [sender_email, setSender]= useState('')
  const [errMsg, setErrMsg]= useState('')
  const [SuccessMsg, setSuccessMsg]= useState('')

  const [createNewProposal, { isLoading: isCreating }] = useCreateNewProposalMutation();
  const [createNewDraft, {isLoading: isSavingDraft} ]= useCreateNewDraftMutation();
  const token = useSelector(selectCurrentToken)

  const [email, setMail]= useState('')
  console.log(email, "MAIL")
  const client = useSelector((state) => selectUsersByEmail(state, email));
  console.log(client, "CLIENT")
  useEffect (()=>{
    if(client[0]){
      setName(client[0].fullName)
      setGST(client[0].GST)
      setPhone(client[0].phone)
    }
    
  }, [client])
  useEffect (()=>{

    if(isClientsSuccess){
      const { ids } = clients
      setIDs(ids)
      
    }
  }, [isClientsSuccess])

  



  useEffect(()=>{
    userRef.current.focus()
  }, [])

  useEffect(()=>{
      if(proposalDetails){
        setClone('Clone Proposal')
      }
  }, [proposalDetails])

  useEffect(() => {
    if (isCreating) {
      setErrMsg('Sending, Please Hold Tight!');
    }if(isSavingDraft){
      setErrMsg('Saving Draft, Please Hold Tight!');
    }
    
  }, [isCreating, isSavingDraft]);

  const handleSubmit = async (e)=>{
    
    e.preventDefault()
    try{
      if(clone==='Clone Proposal') {
        await createNewDraft({proposalId, clients_name, clients_phone, clients_GST, clients_email, sender_email, proposalDetails}).unwrap()
        console.log('Sent Details')
        setEmail('')
        setName('')
        setPhone('')
        setGST('')
        setErrMsg('')
        setSuccessMsg('New Proposal has been initialized!')
        navigate(`./${proposalId}`)
        window.location.reload()
      }if(clone!='Clone Proposal'){ 
        await createNewDraft({proposalId, clients_name, clients_phone, clients_GST, clients_email, sender_email}).unwrap()
        setEmail('')
        setName('')
        setPhone('')
        setGST('')
        setSuccessMsg('Saved Draft!')
        navigate(`./${proposalId}`)
      }
      /* }if(!isNew&&!isDraft){
        onCancel(e)
      } */
     
      
    }catch(err) {
      if(!err.status){
         setErrMsg('No Server Response')

      }else if(err.status === 400){
         setErrMsg('All Fields Required!')
      } else if(err.status === 401){
         setErrMsg('Unauthorized')
      }
      else if(err.status === 409){
        setErrMsg('Already exists!')
     } else{
         setErrMsg(err.data?.message);
      }
      errRef.current.focus();
    }
  }

  const handleNameInput =(e) => setName(e.target.value)
  const handleEmailInput =(e) =>{
    setEmail(e.target.value)
    setMail(e.target.value)
  }
  const handlePhoneInput =(e) => setPhone(e.target.value)
  const handleGSTInput= (e) => setGST(e.target.value)

  const [isNew, setNew]= useState(false)
  const handleONCLICK = () =>{
    if(token){
      const decoded = jwtDecode(token);
      const { fullName, email, role} = decoded.UserInfo;
      setSender(email)
    }
    setNew(true)
    
  }
  
  const [isDraft, setDraft]= useState(false)
  const handleDraft = () =>{
    if(token){
      const decoded = jwtDecode(token);
      const { fullName, email, role} = decoded.UserInfo;
      setSender(email)
    }
    if(clients_name!=""||clients_email!=""||clients_phone!=""||clients_GST!=""||topic!=""){
        setDraft(true)
      }
    
  }


  return (
    <div className="sm:max-w-2xl lg:max-w-3xl p-6 rounded-3xl bg-white shadow-xl">
      <h1 className="font-bold text-2xl text-center mb-4 uppercase">
        {clone}
      </h1>
      
      <form onSubmit={handleSubmit}>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:flex-wrap">
        <div className="mb-4 sm:w-[calc(50%-0.5rem)]">
          <label
            className="font-semibold inline-block mb-1"
            htmlFor="clientEmail"
          >
            Client's Email
          </label>
          <input
            type="text"
            id="clientEmail"
            placeholder="Enter Client's Email"
            className="bg-gray-100 h-10 w-full border-2 rounded-lg px-4 focus:outline-none"
            ref= {userRef}
            value= {clients_email}
            onChange= {handleEmailInput}
          />
          <label
            className="font-semibold inline-block mb-1 text-gray-500 text-sm"
            htmlFor="clientEmail"
          >
            If existing enter email and all details will be filled!
          </label>
        </div>
        <div className="mb-4 sm:w-[calc(50%-0.5rem)] w-full">
          <label
            className="font-semibold inline-block mb-1"
            htmlFor="clientName"
          >
            Client's Name
          </label>
          <input
            type="text"
            id="clientName"
            placeholder="Enter Client's Name"
            className="bg-gray-100 h-10 w-full border-2 rounded-lg px-4 focus:outline-none"
            ref= {userRef}
            value= {clients_name}
            onChange= {handleNameInput}
          />
          
        </div>

        <div className="mb-4 sm:w-[calc(50%-0.5rem)]">
          <label
            className="font-semibold inline-block mb-1"
            htmlFor="clientPhone"
          >
            Client's Phone
          </label>
          <input
            type="text"
            id="clientPhone"
            placeholder="Enter Client's Phone"
            className="bg-gray-100 h-10 w-full border-2 rounded-lg px-4 focus:outline-none"
            ref= {userRef}
            value= {clients_phone}
            onChange= {handlePhoneInput}
          />
        </div>

       

        <div className="mb-4 sm:w-[calc(50%-0.5rem)]">
          <label
            className="font-semibold inline-block mb-1"
            htmlFor="clientGst"
          >
            Client's GST
          </label>
          <input
            type="text"
            id="clientGst"
            placeholder="Enter Client's GST"
            className="bg-gray-100 h-10 w-full border-2 rounded-lg px-4 focus:outline-none"
            ref= {userRef}
            value= {clients_GST}
            onChange= {handleGSTInput}
          />
        </div>


        <div className="sm:w-[calc(100%-0.5rem)] mb-2 flex justify-end items-end gap-5">
       
          <button className="py-2 px-6 rounded-full text-md font-semibold text-white bg-black hover:bg-green-600 transition-colors duration-300" onClick={handleONCLICK}
            type='submit'
          >
            {clone}
          </button>

          <button className="py-2 px-6 rounded-full text-md font-semibold text-gray-500 bg-white border-[1px] border-gray-500 hover:bg-red-600 hover:text-white hover:border-red-600 transition-colors duration-300" onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
      </form>

    </div>
  );
};

export default NewProposal;
