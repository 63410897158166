import { MoonLoader } from 'react-spinners'
import styles from './Loader.module.css'


const Loader= () => {
        return(
            <div className={styles.Spinner}>
                <MoonLoader color="#FFFF"/>
            </div>
        )
}

export default Loader