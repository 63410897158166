import { useState, useCallback, useEffect } from "react";

export const useAddnew = () => {
  const [openAddnew, setOpenAddnew] = useState(false);
  const [isEdit, setEdit] = useState(false);

  const onAddNew = useCallback(
    (e) => {
      if (e.target.innerText === "edit" || isEdit) setEdit((edit) => !edit);
      setOpenAddnew((open) => !open);
    },
    [isEdit, openAddnew]
  );

  return [openAddnew, isEdit, onAddNew];
};

export const useWideScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const onResize = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () =>
      window.removeEventListener("resize", onResize);
  }, [width]);

  return width > 900;
};
