import { useSelector } from 'react-redux';
import { selectCurrentToken } from "../features/auth/authSlice";
import jwtDecode from 'jwt-decode';
import { setAdminDetails } from '../global';

const useAuth = () => {
  const token = useSelector(selectCurrentToken);
  let isAdmin = false;
  let isSuperAdmin = false;
  let status = "Admin";

  if (token) {
    const decoded = jwtDecode(token);
    const { username, role } = decoded.UserInfo;
    
    if (role==="Admin") {
      isAdmin = true;
    } else if (role==="Super Admin") {
      isSuperAdmin = true;
    }

    if (isAdmin) status = "Admin";
    if (isSuperAdmin) status = "Super Admin";

    const Name = username;
    const Role = role;
    setAdminDetails(Name, Role);

    return { username, role, status, isAdmin, isSuperAdmin };
  }

  return { username: '', role: [], isAdmin, isSuperAdmin, status };
};

export default useAuth;
