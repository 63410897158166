import { getProposalno, setProposalno } from "../../global";
import { selectProposalsById } from "./ProposalsApiSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { useState } from "react";
import Modal from "../../components/modal";
import ProposalInfo from "../../components/modal/proposalInfo";
import { downloadPDF } from "../../global";
import NewProposal from "../../components/modal/newProposal";

const Proposal = ({ userId, email }) => {
  const [openCreateNew, setOpenCreateNew] = useState(false);
  const [openView, setOpenView] = useState(false);
  const onClone = () => setOpenCreateNew((open) => !open);
  
  

  const proposal = useSelector((state) => selectProposalsById(state, userId));
  const navigate = useNavigate();
  const onVeiwProposal = () =>{ 
    
    setProposalno()
    navigate(`/proposal/${proposal.clients_name}/${proposal.proposalId}`)
  }

  const onDownloadClick = () => {
    const data= "Client Name: "+proposal.clients_name+'\n'+"Client GST: "+proposal.clients_GST+'\n'+"Client Email: "+proposal.clients_email+'\n'+"Client Phone: "+ proposal.clients_phone+'\n'+"Topic: "+ proposal.topic +'\n'+"Summary: "+ proposal.summary
    //generateAndDownloadPDF(data)
  }
  if (proposal&&!email) {

    const created = new Date(proposal.createdAt).toLocaleString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    
    //const userRolesString = user.roles.toString().replaceAll(',',',')
    let status = "Active";
    if (proposal.status === false) {
      status = "Deactive";
    }
    const cellStatus = proposal.active ? "moments ago" : "inactive";

    return (
      <>
        <tr className="bg-gray-200">
          <td className="py-2 rounded-s-lg w-1/5 min-w-[30px] text-center">{getProposalno()}</td>
          <td className="w-1/5 min-w-[150px]">{proposal.clients_name}</td>
          <td className="w-1/5 min-w-[150px]">{proposal.clients_email}</td>
          <td className="w-1/5 min-w-[150px]">{proposal.clients_phone}</td>
          <td className="w-1/5 min-w-[150px]">{proposal.clients_GST}</td>
          <td className="w-1/5 min-w-[150px]">{created}</td>
          <td className="w-1/5 min-w-[150px]">{proposal.status}</td>
          <td className="rounded-e-lg w-1/5 min-w-[150px]">
                <button
                  className="relative mr-4 text-sm hover:text-yellow-400 transition-colors duration-300 group"
                  onClick={onClone}
                >
                  <span className="material-symbols-outlined">
                    cyclone
                  </span>
                 {/*  <span className="hidden group-hover:inline-block absolute bottom-full left-1/2 transform -translate-x-1/2 p-2 bg-yellow-400 text-white text-xs rounded">
                    Clone
                  </span> */}
                </button>
                <button
                  className="relative mr-4 text-sm hover:text-blue-400 transition-colors duration-300 group"
                  onClick={onVeiwProposal}
                >
                 <span class="material-symbols-outlined ">
                    visibility
                  </span>
                 {/*  <span className="hidden group-hover:inline-block absolute bottom-full left-1/2 transform -translate-x-1/2 p-2 bg-blue-400 text-white text-xs rounded">
                    View
                  </span> */}
                </button>
                <button
                  className="relative italic text-sm hover:text-green-400 transition-colors duration-300 group"
                  onClick={downloadPDF}
                >
                 <span class="material-symbols-outlined">
                    download
                </span>
               {/*  <span className="hidden group-hover:inline-block absolute bottom-full left-1/2 transform -translate-x-1/2 p-2 bg-green-400 text-white text-xs rounded">
                    Download
                  </span> */}
                </button>
          </td>
        </tr>

      <Modal isOpen={openView} onOutsideClick={onVeiwProposal} >
        <ProposalInfo onClose={onVeiwProposal} client_name={proposal.clients_name} client_email={proposal.clients_email} client_phone={proposal.clients_phone} client_GST={proposal.clients_GST} proposal={proposal.summary}/>
      </Modal>
      <Modal isOpen={openCreateNew} onOutsideClick={onClone}>
              <NewProposal onCancel={onClone} proposalDetails={proposal}/>
      </Modal>  
      </>
    );
  } if(proposal&&email){
     console.log(email)
      if(proposal.clients_email===email){
        const created = new Date(proposal.createdAt).toLocaleString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });
        
        //const userRolesString = user.roles.toString().replaceAll(',',',')
        let status = "Active";
        if (proposal.status === false) {
          status = "Deactive";
        }
        const cellStatus = proposal.active ? "moments ago" : "inactive";
    
        return (
          <>
            <tr className="bg-gray-200">
              <td className="py-2 rounded-s-lg w-1/5 min-w-[30px] text-center">{getProposalno()}</td>
              <td className="w-1/5 min-w-[150px]">{proposal.clients_name}</td>
              <td className="w-1/5 min-w-[150px]">{proposal.clients_email}</td>
              <td className="w-1/5 min-w-[150px]">{proposal.clients_phone}</td>
              <td className="w-1/5 min-w-[150px]">{proposal.clients_GST}</td>
              <td className="w-1/5 min-w-[150px]">{created}</td>
              <td className="w-1/5 min-w-[150px]">{proposal.status}</td>
              <td className="rounded-e-lg w-1/5 min-w-[150px]">
              <button
                  className="mr-4 text-sm hover:text-yellow-400 transition-colors duration-300"
                  onClick={onClone}
                >
                 <span class="material-symbols-outlined">
                  cyclone
                  </span>
                </button>
                <button
                  className="mr-4 text-sm hover:text-blue-400 transition-colors duration-300"
                  onClick={onVeiwProposal}
                >
                 <span class="material-symbols-outlined ">
                    visibility
                  </span>
                </button>
                <button
                  className="italic text-sm hover:text-green-400 transition-colors duration-300"
                  onClick={onDownloadClick}
                >
                 <span class="material-symbols-outlined">
                    download
                </span>
                </button>
              </td>
            </tr>
    
          <Modal isOpen={openView} onOutsideClick={onVeiwProposal} >
            <ProposalInfo onClose={onVeiwProposal} />
          </Modal>
          <Modal isOpen={openCreateNew} onOutsideClick={onClone} >
              <NewProposal onCancel={onClone} proposalDetails={proposal}/>
         </Modal>
            
          </>
        );
      }else return null;
  }else return null;
};

export default Proposal;
