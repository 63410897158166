import { useNavigate } from 'react-router-dom'
import { useEffect} from 'react'
import { selectCurrentToken } from "../../features/auth/authSlice"
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { useSendLogoutMutation } from '../../features/auth/authApiSlice';
import { useContext} from "react";
import Modal from "../modal";
import Question from "../modal/question";
import AcceptProposal from "../modal/acceptProposal";
import { AppContext } from "../../context/store";

const Header = ({onSidebar, onPrev, onNext}) => {
  const navigate = useNavigate();
  const [sendLogout, { isLoading, isSuccess, isError, error }] =
    useSendLogoutMutation();

  useEffect(() => {
    if (isSuccess) navigate("/");
  }, [isSuccess, navigate]);

  const onLogoutClick = () => sendLogout();

  let AdminName
  const token = useSelector(selectCurrentToken)
  if(token){
    const decoded = jwtDecode(token);
    const { fullName, email, role} = decoded.UserInfo;
    AdminName= fullName
  }
  const { question, accept } = useContext(AppContext);
  const [isQuestionOpen,setQuestionOpen] = question
  const [isAcceptOpen,setAcceptOpen] = accept

  const closeQuestion = () => setQuestionOpen((open) => !open);
  const closeAccept = () => setAcceptOpen((open) => !open);
  return (
    <div className="w-full h-16 px-4 sticky top-0 bg-white z-50">
      <div className="h-16 flex justify-between items-center">
        <div className="w-[140px] h-fit px-4 flex items-center justify-center gap-4">
          <button onClick={onSidebar}>
            <span className="material-symbols-outlined text-3xl" >menu</span>
          </button>
          <img
            src="/assets/images/alphabetLogoDark.png"
            className="object-fill"
          />
        </div>
        
      <div className="flex justify-end items-center gap-4">
      <button
        className="h-[36px] px-4 text-md rounded-md font-semibold text-white bg-black uppercase hidden sm:block hover:bg-green-600 transition-colors duration-300"
        onClick={closeAccept}
      >
        Accept
      </button>

        <button
        className="py-1 px-2 h-[36px] rounded-md bg-gray-300 flex-col items-center hidden sm:flex hover:bg-yellow-500 hover:text-white transition-colors duration-300"
        onClick={closeQuestion}
      >
        <span className="material-symbols-outlined text-[15px]">
          speaker_notes
        </span>
        <span className="text-[8px] font-semibold">Question</span>
      </button>

        <button
          className="h-[36px] w-10 rounded-md bg-gray-300 flex items-center justify-center hover:bg-black hover:text-white transition-colors duration-300"
          onClick={onPrev}
        >
          <span className="material-symbols-outlined text-lg font-semibold">
            arrow_back_ios
          </span>
        </button>
      
        <button
          className="h-[36px] w-10 rounded-md bg-gray-300 flex items-center justify-center hover:bg-black hover:text-white transition-colors duration-300"
          onClick={onNext}
        >
          <span className="material-symbols-outlined text-lg font-semibold">
            arrow_forward_ios
          </span>
        </button>
      </div>
     

      <Modal isOpen={isAcceptOpen}>
        <AcceptProposal onClose={closeAccept} />
      </Modal>

      <Modal isOpen={isQuestionOpen} onOutsideClick={closeQuestion}>
        <Question onClose={closeQuestion} />
      </Modal>
    </div>
      </div>
   
  );
};

export default Header;
