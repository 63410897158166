import React, { useState, useEffect } from 'react';
import { useEditProposalMutation } from '../../../features/proposals/ProposalsApiSlice';
import { useParams } from 'react-router-dom';
import { selectProposalsByProposalId } from '../../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetProposalsQuery } from '../../../features/proposals/ProposalsApiSlice';
import { handleConvertToPdf } from '../../../global';
const Introduction = ({slideAnimation}) => {
  
  const { id } = useParams();
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  console.log(id)
  const proposalId = id
  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    console.log(proposal)
  }
  const [clientName, setClientName] = useState(
    `Dear, Client!`
  );
  const [dearClient, setDearClient] = useState(
    `
    I trust this proposal finds you well. We are excited to present you with a comprehensive solution that is custom-designed to propel your business toward unprecedented success.\n
    We have collected all the information in this dynamic proposal for your perusal. Within its pages, you will discover a blueprint that encompasses our understanding of your aspirations, challenges, and the transformative solutions we propose to implement.\n
    Your time is valuable to us, and we are eager to engage in a constructive discussion to address any queries or insights you may have. Please feel free to reach out to us at your earliest convenience. We believe that collaboration is the cornerstone of success, and we are committed to working closely with you to realize your business objectives.\n
    Thank you for considering our proposal. We are genuinely excited about the opportunity to partner with you and contribute to your growth story.
    We look forward to the possibility of discussing our proposal further with you. `
  );

  const [yoursSincerely, setYoursSincerely] = useState('Aparna Chugh, \n Director - Strategy \n Alphabet Factory Inc®');

  const [aboutCompany, setAboutCompany] = useState(
    `We're writers, directors, strategists, and great chit-chatters.\nUsing year-over-year development methodologies and adapting the best market practices, we will ensure that your new requirement will be visible, accessible, and help you build a 100x product. We’re a growing team of experts and thought leaders full of personality minus ego. We’re the wearer of many hats dedicated to helping companies like yours grow.\n
    We deliver everything you need to build deeper relationships with your most important audiences. We deliver success! 
    `
  );

  const [whyCompany, setWhyCompany] = useState(
    `At Alphabet Factory, we bring a dynamic fusion of creativity and expertise to the forefront. With a team comprising writers, designers, technologists, strategists, and conversational wizards, we're not just crafting content – we're shaping experiences.
     \nOur commitment to excellence is unwavering. With successful track record on delivery and development methodologies and embracing top-tier market practices, we transform your requirements into more than just solutions; we forge pathways to unparalleled success. Expect your vision to not only be visible and accessible but to transcend expectations, resulting in a product that stands
    `
  );
  const [backgroundImage, setBackgroundImage] = useState(
    '/public/assets/images/backgroundImage.jpg'
  );
  const [createProposal, {isLoading}]= useEditProposalMutation()

  const [imageBlob, setBlob]= useState()
  
  const handleBackgroundImageChange = async(e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
      const blobData = await (await fetch(backgroundImage)).blob();
      setBlob(blobData)
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      console.log(imageBlob)
      
      console.log('Background Image', backgroundImage)
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createProposal({
        introductionData: {
          clientName,
          dearClient,
          yoursSincerely,
          aboutCompany,
          whyCompany,    
        }, proposalId
      }).unwrap();
      console.log('Proposal created successfully', response);
    } catch (error) {
      console.error('Error creating proposal:', error);
    }
  };

  // Function to handle beforeunload event
  const handleBeforeUnload = (e) => {
    // Call the handleSubmit function here
    handleSubmit(e);
    // Clear the prompt message (modern browsers ignore the text)
    delete e.returnValue;
  };

  
  /* useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); */

  useEffect(() => {
    if (proposal) {
      console.log("proposal:", proposal);
      if(proposal.length > 0 && proposal[0].clients_name){
        console.log("proposal:", proposal);
        setClientName(`Namasthe, ${proposal[0].clients_name}!`)
        }
      if (proposal.length > 0 && proposal[0].introductionData) {
        // Access the clientName property only if introductionData exists
        setClientName(proposal[0].introductionData.clientName);
        setDearClient(proposal[0].introductionData.dearClient);
        setYoursSincerely(proposal[0].introductionData.yoursSincerely);
        setAboutCompany(proposal[0].introductionData.aboutCompany);
        setWhyCompany(proposal[0].introductionData.whyCompany);
      }
    }
  }, [proposal]);
  
  useEffect(() => {
    // Function to call handlePdfConversion when the component mounts
    
    if (proposal) {
        
        if(proposal.length > 0){
              if(clientName.includes(proposal[0].clients_name)){
                handleConvertToPdf('introduction-pdf-content');
          }
        }
      }
    
   
    //convertToPdfOnMount(); // Call the function when the component mounts
  }, [proposal, clientName]); // The empty dependency array ensures it runs only on mount
  

  return (
    <>
      < div id='introduction-pdf-content' className={`${slideAnimation} flex flex-col  md:flex-row md:items-stretch`}>        <div className="md:w-[55%] min-h-80 bg-black text-white p-4 md:8 text-justify">
          <h3
            className="text-lg font-semibold text-sm"
            
            onBlur={(e) => setClientName(e.target.textContent)}
           
          >
            {clientName}
          </h3>
          <p className="text-left text-sm">
          {dearClient.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
</p>
          <p className="mt-4 text-sm">
          Kind regards,</p>
          <p className="mt-4 text-sm"  onBlur={(e) => setYoursSincerely(e.target.textContent)}>
          {yoursSincerely.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
          </p>
        </div>
        <div className="md:w-[45%] min-h-[320px] bg-[url('/public/assets/images/aparna-ceo-image.png')] bg-no-repeat bg-cover">
          
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:items-stretch bg-white border-b-[20px] border-red-600 py-2">
        <div className="md:w-[55%] min-h-[400px] px-4 sm:px-10 mt-4">
          <h2 className="text-2xl"><b>About Alphabet Factory</b></h2>
          <p
            className="mt-4 text-sm"
            
            onBlur={(e) => setAboutCompany(e.target.textContent)}
           
          >
            {aboutCompany.split('\n').map((line, index) => (
                    <span key={index}>
                    {index === 0 ? <b>{line}</b> : line}
                    <br />
                   </span>
              ))}
          </p>
        </div>
        <div className=" md:w-[45%] min-h-[400px] px-4 sm:px-10 mt-4">
          <h2 className="text-2xl"><b>Why Choose Us?</b></h2>
          <p
            className="mt-4 text-sm"
            
            onBlur={(e) => setWhyCompany(e.target.textContent)}
           
          >
            {whyCompany.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
          </p>
        </div>
       
      </div>
    </>
  );
};

export default Introduction;
