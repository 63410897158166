import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/store";
import { useParams } from 'react-router-dom';
import { useGetProposalsQuery } from '../../features/proposals/ProposalsApiSlice';
import { selectProposalsByProposalId } from '../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';

const Sidebar = ({ isOpen, onClose }) => {
  const { question, accept, slidesName, selectedSlide } = useContext(AppContext);
  const [, setQuestionOpen] = question;
  const [, setAcceptOpen] = accept;
  const [SelectedSlide, setSelectedSlide] = selectedSlide;
  const { id } = useParams();

  const proposalId = id
  const [proposalName, setName] = useState('New Proposal')
  const [date, setDate] = useState('Today')
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  
  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    console.log(proposal)
  }

  useEffect(() => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    if (proposal) {
      console.log("proposal:", proposal);

      if (proposal.length > 0 && proposal[0].coverData) {
        // Access the clientName property only if introductionData exists
        setName(proposal[0].coverData.proposalTitle);
        const formattedDate=proposal[0].createdAt.toLocaleString(undefined, options)
        setDate(formattedDate)
        
      }
    }
  }, [proposal]);

  const onModalOpen = ({ target: { name } }) => {
    if (name === "accept") setAcceptOpen(true);
    else setQuestionOpen(true);
    onClose();
  };

  const onNavClick = ({ target: { name } }) => {
    setSelectedSlide(name);
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="w-80 h-full bg-white absolute top-0 left-0 py-4 z-[100] shadow-2xl animate-move-in">
          <div className="flex justify-between items-center">
            <div className=" px-4 pb-2 w-full">
              <h2 className="font-bold">{proposalName}</h2>
              <p className="text-xs font-semibold">{date}</p>
            </div>

            <button className="p-4 hover:text-red-600" onClick={onClose}>
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
          <div className="text-sm text-black ">
            <button
              name="accept"
              onClick={onModalOpen}
              className={`flex items-center gap-2 ${styles.navLink} w-full block `}
            >
              <span className="material-symbols-outlined">done</span>
              Accept Proposal
            </button>

            <button
              name="question"
              onClick={onModalOpen}
              className={`flex items-center gap-2 ${styles.navLink} w-full block `}
            >
              <span className="material-symbols-outlined">speaker_notes</span>
              <span>Question</span>
            </button>
            {slidesName.map((slide) => (
              <button
                key={slide}
                className={
                  slide === SelectedSlide ? styles.activeLink : styles.navLink
                }
                name={slide}
                onClick={onNavClick}
              >
                {slide}
              </button>
            ))}
            <a href="https://alphabetfactory.com/about/" target="_blank">
            <button
              name="aboutus"
              
              className={`flex items-center gap-2 ${styles.navLink} w-full block`}
              
            >
              
              About Us
              
            </button>
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  navLink:
    "w-full text-left py-2 px-4 border-b-[1px] border-b-gray-300 hover:bg-red-600 hover:text-white  cursor-pointer capitalize",
  activeLink:
    "bg-red-600 text-white w-full text-left py-2 px-4 border-b-[1px] border-b-gray-300  cursor-pointer capitalize",
};

export default Sidebar;
