import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/store";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { useEditDraftMutation } from '../../features/drafts/DraftApiSlice';
import { useGetDraftsQuery } from '../../features/drafts/DraftApiSlice';
import { selectDraftsByProposalId } from '../../features/drafts/DraftApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';


function createCustomizedLink(baseUrl,params, link) {
  const queryString = params
  const fullUrl = link;

  // Copy the link to the clipboard (for modern browsers supporting clipboard API)
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(fullUrl)
      .then(() => {
        console.log('Link copied to clipboard:', fullUrl);
      })
      .catch((error) => {
        console.error('Failed to copy link to clipboard:', error);
      });
  } else {
    console.warn('Clipboard API not supported in this browser.');
  }
}



const Sidebar = ({ isOpen, onClose }) => {
  const navigate = useNavigate()
  const { id } = useParams();

  const proposalId = id
  const {
    data: drafts,
    isSuccess,
    isError,
    error
  } = useGetDraftsQuery()
  let content
  const [link, setLink]= useState('Get Link')

  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = drafts
    
  }
  console.log(id)

  
  const draft = useSelector((state) => selectDraftsByProposalId(state, proposalId));
  const [links, setClientLink] = useState('https://main.duj0q0015isev.amplifyapp.com/proposal/'+proposalId)

  if(draft){
    console.log(draft)
  }
  const [proposalName, setName] = useState('New Proposal')

  const handCreateLink = () =>{
    const baseUrl= 'https://proposals.alphabetfactory.com/proposal/'
    const prams = proposalId
    
    createCustomizedLink(baseUrl, prams, links)
    setLink('Link Copied')
  }

  const { question, accept, slidesName, selectedSlide } =
    useContext(AppContext);
  const [, setQuestionOpen] = question;
  const [, setAcceptOpen] = accept;
  const [SelectedSlide, setSelectedSlide] = selectedSlide;
  const [sendProp, setSend]= useState(true)
  const onModalOpen = ({ target: { name } }) => {
    if (name === "accept") setAcceptOpen(true);
    else setQuestionOpen(true);
    onClose();
  };

  const onNavClick = ({ target: { name } }) => {
    setSelectedSlide(name);
    onClose();
  };
  

  const handleGoBack = () =>{
      navigate('/proposals')
      window.location.reload()
  }
  const [send, setSent]= useState('Send')
  const [date, setDate] = useState('Today')
  const [createDraft, {isLoading}]= useEditDraftMutation()
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      await createDraft({proposalId, sendProp, links}).unwrap();
      console.log('Draft created successfully');
      setSent('Successfully Sent!')
      
    } catch (error) {
      console.error('Error creating draft:', error);
    }
  };
  
  useEffect(() => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    if (draft) {
      console.log("draft:", draft);
      if(draft.length > 0){
        setClientLink('https://proposals.alphabetfactory.com/proposal/'+draft[0].clients_name+'/'+proposalId)
      }
      if (draft.length > 0 && draft[0].coverData) {
        // Access the clientName property only if introductionData exists
        setName(draft[0].coverData.proposalTitle);
        setDate(draft[0].createdAt.toLocaleString(undefined, options))
      }
    }
  }, [draft]);

  return (
    <>
      {isOpen && (
        <div className="w-80 h-full bg-white absolute top-0 left-0 py-4 z-[100] shadow-2xl animate-move-in">
          <div className="flex justify-between items-center">
            <div className=" px-4 pb-2 w-full">
              <h2 className="font-bold">{proposalName}</h2>
              <p className="text-xs font-semibold">{date}</p>
            </div>

            <button className="p-4 hover:text-red-600" onClick={onClose}>
              <span className="material-symbols-outlined">close</span>
            </button>
          </div>
          <div className="text-sm text-black ">
            <button
              name="goBack"
              onClick={handleGoBack}
              className={`flex items-center gap-2 ${styles.navLink} w-full block`}
            >
              <span className="material-symbols-outlined">arrow_back</span>
              Go Back
            </button>

            <button
              name="send"
              onClick={handleSubmit}
              className={`flex items-center gap-2 ${styles.navLink} w-full block`}
              disabled = {send === 'Successfully Sent!'}
            >
              <span class="material-symbols-outlined">send</span>
              <span>{send}</span>
            </button>

            {slidesName.map((slide) => (
              <button
                key={slide}
                className={
                  slide === SelectedSlide ? styles.activeLink : styles.navLink
                }
                name={slide}
                onClick={onNavClick}
              >
                {slide}
              </button>
            ))}
            <a href="https://alphabetfactory.com/about/" target="_blank">
            <button
              name="aboutus"
              
              className={`flex items-center gap-2 ${styles.navLink} w-full block`}
              
            >
              
              About Us
              
            </button>
            </a>
            <button
              name="generateLink"
              onClick={handCreateLink}
              className={`flex items-center gap-2 ${styles.navLink} w-full block`}
              disabled = {link==='Link Copied'}
            >
              
              <span>{link}</span>
              <span className="material-symbols-outlined">link</span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  navLink:
    "w-full text-left py-2 px-4 border-b-[1px] border-b-gray-300 hover:bg-red-600 hover:text-white  cursor-pointer capitalize",
  activeLink:
    "bg-red-600 text-white w-full text-left py-2 px-4 border-b-[1px] border-b-gray-300  cursor-pointer capitalize",
};

export default Sidebar;
