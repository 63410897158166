import{
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const proposalsAdapter = createEntityAdapter({})

const initialState = proposalsAdapter.getInitialState()

export const ProposalsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder =>({
        getProposals: builder.query({
            query: ()=> '/proposals',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            keepUnusedDataFor: 5,
            transformResponse: responseData =>{
                const loadedProposals = responseData.map(proposal => {
                    proposal.id = proposal._id
                    return proposal
                });
                return proposalsAdapter.setAll(initialState, loadedProposals)
            },
            providesTags: (result, error, arg) =>{
                if(result?.ids){
                    return [
                        {type: 'Proposal', id: 'List'},
                        ...result.ids.map(id=> ({type: 'Proposal', id}))
                    ]
                }else return [{type: 'Proposal', id: 'LIST'}]
            }

        }),
        createNewProposal: builder.mutation({
            query: credentials => ({
                url: '/proposals',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        editProposal: builder.mutation({
            query: credentials => ({
                url: '/proposals',
                method: 'PATCH',
                body: { ...credentials }
            })
        }),
        deleteProposal: builder.mutation({
            query: credentials => ({
                url: '/proposals',
                method: 'DELETE',
                body: { ...credentials }
            })
        }),
    })
})
 export const {
    useGetProposalsQuery,
    useCreateNewProposalMutation,
    useEditProposalMutation,
    useDeleteProposalMutation,
    
 } = ProposalsApiSlice

 export const selectProposalsResult =  ProposalsApiSlice.endpoints.getProposals.select()

 const selectProposalsData = createSelector(
    selectProposalsResult,
    proposalsResult => proposalsResult.data
 )

 export const {
    selectAll: selectAllProposals,
    selectById: selectProposalsById,
    selectIds: selectProposalIds
 } = proposalsAdapter.getSelectors(state => selectProposalsData(state) ?? initialState)

 export const selectProposalsByProposalId = createSelector(
    selectAllProposals,
    (_, proposalId) => proposalId,
    (proposals, proposalId) => proposals.filter(proposal => proposal.proposalId === proposalId)
  );