import { useContext} from "react";
import Modal from "../modal";
import Question from "../modal/question";
import AcceptProposal from "../modal/acceptProposal";
import { AppContext } from "../../context/store";

const ProposalSlide = ({ children, onNext, onPrev }) => {
  const { question, accept } = useContext(AppContext);
  const [isQuestionOpen,setQuestionOpen] = question
  const [isAcceptOpen,setAcceptOpen] = accept

  const closeQuestion = () => setQuestionOpen((open) => !open);
  const closeAccept = () => setAcceptOpen((open) => !open);

  return (
   <>
     
      <div>
        {children}
      </div>

      <Modal isOpen={isAcceptOpen}>
        <AcceptProposal onClose={closeAccept} />
      </Modal>

      <Modal isOpen={isQuestionOpen} onOutsideClick={closeQuestion}>
        <Question onClose={closeQuestion} />
      </Modal>
   </>
  );
};

export default ProposalSlide;
