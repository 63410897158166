import React, { useState, useEffect } from 'react';
import { useEditDraftMutation } from '../../../features/drafts/DraftApiSlice';
import { useParams } from 'react-router-dom';
import { selectDraftsByProposalId } from '../../../features/drafts/DraftApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetDraftsQuery } from '../../../features/drafts/DraftApiSlice';

const PlanOfAction = ({slideAnimation, submission, setSubmission}) => {

   
  const { id } = useParams();
  const {
    data: drafts,
    isSuccess,
    isError,
    error
  } = useGetDraftsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = drafts
    
  }
  console.log(id)
  const proposalId = id
  const draft = useSelector((state) => selectDraftsByProposalId(state, proposalId));

  if(draft){
    console.log(draft)
  }
  
  const [heading, setHeading] = useState('Development Approach');
  const [subHeading, setSubHeading] = useState(
    'To make our collaboration a great success, we propose the following action plan. Of course, we will refine this further as soon as we get started. All your feedback is indispensable for a perfect result.'
  );

  const [actionPlan, setActionPlan] = useState([
    {
      phase: 'Preparation',
      information:
        'The final schedule is drawn up and the most important requirements are prepared, To make our collaboration a great success, we propose the following action plan. Of course, we will refine this further as soon as we get started. All your feedback is indispensable for a perfect result',
      who: 'Alphabet Factory',
      week: '1',
    },
  ]);

  const addNewRow = () => {
    setActionPlan((prevActionPlan) => [
      ...prevActionPlan,
      {
        phase: '',
        information: '',
        who: '',
        week: '',
      },
    ]);
  };

  const removeRow = (index) => {
    setActionPlan((prevActionPlan) => prevActionPlan.filter((item, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    setActionPlan((prevActionPlan) =>
      prevActionPlan.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  const [createDraft, {isLoading}]= useEditDraftMutation()
  const [save, setSaved]= useState('Save')

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createDraft({
        planofactionData: {
          heading,
          subHeading,
          actionPlan, 
        }, proposalId
      }).unwrap();
      console.log('Draft created successfully', response);
      setSaved('Saved!')
      window.location.reload()
    } catch (error) {
      console.error('Error creating draft:', error);
    }
  };

  useEffect(() => {
    if (draft) {
      console.log("draft:", draft);

      if (draft.length > 0 && draft[0].planofactionData) {
        // Access the clientName property only if planofactionData exists
        setHeading(draft[0].planofactionData.heading);
        setSubHeading(draft[0].planofactionData.subHeading);
        setActionPlan(draft[0].planofactionData.actionPlan);
       
      }
    }
  }, [draft]);

  return (
    <div id="plan-pdf-content" className={`${slideAnimation}`}>
      <div className="relative z-10"> {/* Increase the z-index to a higher value */}
         <button
           className="bg-blue-300 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 hover:text-white transition-colors duration-300 absolute top-0 right-0"
           onClick={handleSubmit}
           disabled={save === 'Saved!'}
         >
          {save}
         </button>
      </div>
      <div className="min-h-[768px] bg-white p-3 md:p-8">
        <h1
          className="text-2xl font-semibold"
          contentEditable
          onBlur={(e) => setHeading(e.target.textContent)}
          suppressContentEditableWarning={true}
        >
          {heading}
        </h1>
        <p
          className="text-sm mt-4 text-justify"
          contentEditable
          onBlur={(e) => setSubHeading(e.target.textContent)}
          suppressContentEditableWarning={true}
        >
          {subHeading}
        </p>
        <div className="overflow-auto sm:w-auto md:w-auto">
          <table className="w-full sm:w-auto md:w-auto mt-4">
            <thead className="bg-black text-white h-8 text-left">
              <tr>
                <th className="w-[20%] px-4">Milestone</th>
                <th className="w-[50%] px-4">Description</th>
                <th className="w-[20%] px-4">Deliverables</th>
                <th className="w-[10%] px-4">Timeline</th>
                <th className="w-[10%] px-4"></th>
              </tr>
            </thead>

            <tbody className="text-sm">
              {actionPlan.map((row, index) => (
                <tr key={index} className="h-8 even:bg-gray-200">
                  <td className="px-4 w-1/5">
                    <textarea
                      type="text"
                      className="w-full h-40"
                      value={row.phase}
                      onChange={(e) => handleInputChange(index, 'phase', e.target.value)}
                    />
                  </td>
                  <td className="px-4 w-2/5 whitespace-pre-wrap">
                    <textarea
                      type="text"
                      className="w-full h-40"
                      value={row.information}
                      onChange={(e) => handleInputChange(index, 'information', e.target.value)}
                    />
                  </td>
                  <td className="px-4 w-1/5">
                    <textarea
                      type="text"
                      className="w-full h-40"
                      value={row.who}
                      onChange={(e) => handleInputChange(index, 'who', e.target.value)}
                    />
                  </td>
                  <td className="px-4 w-1/5">
                    <textarea
                      type="text"
                      className="w-full h-40"
                      value={row.week}
                      onChange={(e) => handleInputChange(index, 'week', e.target.value)}
                    />
                  </td>
                  <td className="px-4">
                    <button onClick={() => removeRow(index)} class="hover:text-red-600"><span class="material-symbols-outlined text-lg">delete
                    </span></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-4">
          <button onClick={addNewRow} className="mr-2 text-green hover:text-green-600">
            <span className="material-symbols-outlined text-lg font-semibold ">add_circle</span>
          </button>
        </div>
      </div>
      
    </div>
  );
};

export default PlanOfAction;
