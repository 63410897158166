import React, { useState, useEffect } from 'react';
import { useEditProposalMutation } from '../../../features/proposals/ProposalsApiSlice';
import { useParams } from 'react-router-dom';
import { selectProposalsByProposalId } from '../../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetProposalsQuery } from '../../../features/proposals/ProposalsApiSlice';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { handleConvertToPdf } from '../../../global';
import { getscannedItem } from '../../../global';

const Investment = ({ slideAnimation }) => {
  const { id } = useParams();
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  console.log(id)
  const proposalId = id
  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    console.log(proposal)
  }
  
  const [textContent, setTextContent] = useState(`
  In the realm of commercials, we go beyond crafting mere advertisements – we sculpt captivating narratives that resonate and drive impact. Our approach blends artistic finesse with strategic acumen, ensuring your brand's essence shines through every frame.

With a keen understanding of market dynamics, we curate commercials that stand out amidst the noise. Our commitment to excellence is matched only by our dedication to delivering optimal value. From storyboard to screen, we meticulously manage resources, maximizing your returns on investment.

`);


const [tableContent, setTableContent] = useState([
  {
    description: "MileStone 1",
    price: 20,
    subtotal: "15000",
  },
  {
    description: "MileStone 2",
    
    price: 20,
    subtotal: "15000",
  },
  {
    description: "MileStone 3",
    
    price: 20,
    subtotal: "15000",
  },
  {
    description: "MileStone 4",
    price: 20,
    subtotal: "15000",
  },
  {
    description: "MileStone 5",
    price:20,
    subtotal: "15000",
  },
]);

// Function to calculate the subtotal of services and discount


  const handleTextContentChange = (event) => {
    setTextContent(event.target.textContent);
  };

  const handleTableContentChange = (event, index, field) => {
    const updatedTableContent = [...tableContent];
    updatedTableContent[index][field] = event.target.value;
    setTableContent(updatedTableContent);
  };

  const handleCheckboxChange = (index) => {
    const updatedTableContent = [...tableContent];
    updatedTableContent[index].count = updatedTableContent[index].count === 0 ? 1 : 0;
    setTableContent(updatedTableContent);
  };
  const [termsAndConditions, setTermsAndConditions] = useState(
    `- 30 Days maintenance work free for all the Content, functionality we developed and delivered.\n` +
    `- A delay in processing invoice beyond 15 working days, we will put a hold to the project and release the resources.\n` +
    `- The cost of any third-party tools, stock images, and licenses, if required, has not been included in our proposal.\n` +
    `- There is a possibility of Change of Estimation after Milestone 1 and if our Architects find a gap in requirements or new requirements that arise during the finalization phase. This will be done on mutual agreement.\n` +
    `- Client will be responsible for providing timely feedback within 7 working days.\n` +
    `- Any government taxes like GST etc will be applicable according to norms.`
  );

  const [createProposal, {isLoading}]= useEditProposalMutation()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

    
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createProposal({
        investmentData: {
          textContent,
          tableContent   
        }, proposalId
      }).unwrap();
      console.log('Proposal created successfully', response);
    } catch (error) {
      console.error('Error creating proposal:', error);
    }

  };
  /* const handleConvertToPdf = async () => {
    
    const pdf = new jsPDF({
      orientation: 'landscape', // Set orientation to landscape for a presentation page
      format: 'a4', // You can change the format to other predefined formats as well
    });
  
 
  
    
      const pageContent = document.getElementById('investment-pdf-content');
      const canvas = await html2canvas(pageContent, { scrollY: -window.scrollY });
      const imageData = canvas.toDataURL('image/png');
      pdf.addImage(imageData, 'PNG', 0, 0, 290, 210); // Adjust the positioning and size for 16:9 aspect ratio
      pdf.addPage();
    
    pdf.save('proposals_slides.pdf');
  }; */

  const [TotalPayable, setPayable] = useState('INR 201203')
  useEffect(() => {
    if (proposal) {
      console.log("proposal:", proposal);

      if (proposal.length > 0 && proposal[0].investmentData) {
        // Access the clientName property only if investmentData exists
        setTextContent(proposal[0].investmentData.textContent);
        const tableContentCopy = JSON.parse(JSON.stringify(proposal[0].investmentData.tableContent));
        setTableContent(tableContentCopy);
        setPayable(proposal[0].investmentData.TotalPayable)
        setTermsAndConditions(proposal[0].investmentData.termsAndConditions)
      }
    }
  }, [proposal]);
  const scannedItem = getscannedItem()
  useEffect (()=>{
    if (proposal) {
      //console.log("proposal:", proposal);
      if(proposal.length > 0 ){
        if(proposal[0].investmentData.TotalPayable===TotalPayable){
          handleConvertToPdf('investment-pdf-content');
       }
    }
    }
  },[proposal, TotalPayable])
  return (
    <>
    
    <div id="investment-pdf-content" className={`${slideAnimation} h-[900px] flex flex-col lg:flex-row items-stretch`}>
      
      <div className="lg:w-1/2 bg-white p-4 md:p-8">
        <h2 className="text-2xl text-black font-bold">Development Commercials</h2>
        <p className="text-sm mt-4" >
          {textContent}
        </p>
        <br/>
        <div className="max-w-full">
        <table className="w-full">
            <thead className="bg-black text-white h-8 text-left">
              <tr>
                <th className="w-[50%] px-2 border-solid border-2 border-black">TOTAL PAYABLE</th>   
                <th className="w-[50%] px-2 bg-white text-black border-solid border-2 border-black 

                "><span >
                        
                        
                          {TotalPayable}
                         
                      </span></th>
               
                <th></th>
              </tr>
            </thead>
          </table>
          <br/>
          <h2 className="text-1xl text-black font-bold">Payment Schedule</h2>
  <table className="w-full mt-4 table-auto">
    <thead className="bg-black text-white h-8 text-left">
      <tr>
      <th className="w-[40%] px-2">Milestone</th>   
                <th className="w-[35%] px-2">% in total project</th>
                <th className="w-[25%] px-2">Total Payable</th>
              
      </tr>
    </thead>

    <tbody className="text-sm">
    {tableContent.map((row, index) => (
                <tr key={index} className="h-8 even:bg-gray-200">
                  <td className="px-2 truncate">  
                      
                      <span >
                      {row.description}
                        
                      </span>
                     
                  </td>
                  
                  <td className="px-2 truncate">
                  {row.price}
                    
                  </td>
                  <td className="px-2 truncate">
                  {row.subtotal}
                    
                  </td>
                </tr>
              ))}
    </tbody>
  </table>
</div>
<br/>

<h2 className="text-2xl text-black font-bold">Terms & Conditions</h2>
<div class="py-1 " styles="margin-left: -1px; margin-right: -1px;">
          <ul class="list-none pl-4 space-y-2 text-sm">
             
             {termsAndConditions.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
          </ul>
        </div>

      </div>
      <div className="lg:w-1/2 h-full min-h-96 bg-[url('/public/assets/images/backgroundImage.jpg')] bg-cover bg-no-repeat">
        <h1 className="p-4 md:w-[70%] mx-auto pt-16 md:pt-40 text-white text-3xl text-center">
          We look forward to getting started and to work on your high-quality production
        </h1>
      </div>
      
    </div>
    </>
  );
};

export default Investment;
