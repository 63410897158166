import React, { useState, useEffect } from 'react';
import { useEditDraftMutation } from '../../../features/drafts/DraftApiSlice';
import { useParams } from 'react-router-dom';
import { selectDraftsByProposalId } from '../../../features/drafts/DraftApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetDraftsQuery } from '../../../features/drafts/DraftApiSlice';


const Introduction = ({slideAnimation, submission, setSubmission}) => {
  
  const { id } = useParams();
  const {
    data: drafts,
    isSuccess,
    isError,
    error
  } = useGetDraftsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = drafts
    
  }
  console.log(id)
  const proposalId = id
  const draft = useSelector((state) => selectDraftsByProposalId(state, proposalId));

  if(draft){
    console.log(draft)
  }
  const [clientName, setClientName] = useState(
    `Dear, Client!`
  );
  const [dearClient, setDearClient] = useState(
    `I trust this proposal finds you well. We are excited to present you with a comprehensive solution that is custom-designed to propel your business toward unprecedented success.\n
We have collected all the information in this dynamic proposal for your perusal. Within its pages, you will discover a blueprint that encompasses our understanding of your aspirations, challenges, and the transformative solutions we propose to implement.\n
Your time is valuable to us, and we are eager to engage in a constructive discussion to address any queries or insights you may have. Please feel free to reach out to us at your earliest convenience. We believe that collaboration is the cornerstone of success, and we are committed to working closely with you to realize your business objectives.\n
Thank you for considering our proposal. We are genuinely excited about the opportunity to partner with you and contribute to your growth story. We look forward to the possibility of discussing our proposal further with you.`
  );

  const [yoursSincerely, setYoursSincerely] = useState('Aparna Chugh, \n Director - Strategy \n Alphabet Factory Inc®');

  const [aboutCompany, setAboutCompany] = useState(
    `We're writers, directors, strategists, and great chit-chatters.\nUsing year-over-year development methodologies and adapting the best market practices, we will ensure that your new requirement will be visible, accessible, and help you build a 100x product. We’re a growing team of experts and thought leaders full of personality minus ego. We’re the wearer of many hats dedicated to helping companies like yours grow.\n\nWe deliver everything you need to build deeper relationships with your most important audiences. We deliver success! 
    `
  );

  const [whyCompany, setWhyCompany] = useState(
    `At Alphabet Factory, we bring a dynamic fusion of creativity and expertise to the forefront. With a team comprising writers, designers, technologists, strategists, and conversational wizards, we're not just crafting content – we're shaping experiences.

Our commitment to excellence is unwavering. With successful track record on delivery and development methodologies and embracing top-tier market practices, we transform your requirements into more than just solutions; we forge pathways to unparalleled success. Expect your vision to not only be visible and accessible but to transcend expectations, resulting in a product that stands
    `
  );
  const [backgroundImage, setBackgroundImage] = useState(
    '/public/assets/images/backgroundImage.jpg'
  );
  const [createDraft, {isLoading}]= useEditDraftMutation()

  const [imageBlob, setBlob]= useState()
  
  const handleBackgroundImageChange = async(e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBackgroundImage(reader.result);
      };
      reader.readAsDataURL(file);
      const blobData = await (await fetch(backgroundImage)).blob();
      setBlob(blobData)
    }
  };
  
  const [save, setSaved]= useState('Save')

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      console.log(imageBlob)
      
      
      console.log('Background Image', backgroundImage)
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createDraft({
        introductionData: {
          clientName,
          dearClient,
          yoursSincerely,
          aboutCompany,
          whyCompany,    
        }, proposalId
      }).unwrap();
      console.log('Draft created successfully', response);
      setSaved('Saved!')
      window.location.reload()
    } catch (error) {
      console.error('Error creating draft:', error);
    }
  };

  // Function to handle beforeunload event
  const handleBeforeUnload = (e) => {
    // Call the handleSubmit function here
    handleSubmit(e);
    // Clear the prompt message (modern browsers ignore the text)
    delete e.returnValue;
  };

  
  /* useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); */

  useEffect(() => {
    if (draft) {
      if(draft.length > 0 && draft[0].clients_name){
      console.log("draft:", draft);
      setClientName(`Namasthe ${draft[0].clients_name}!`)
      }
      if (draft.length > 0 && draft[0].introductionData) {
        // Access the clientName property only if introductionData exists
        setClientName(draft[0].introductionData.clientName);
        setDearClient(draft[0].introductionData.dearClient);
        setYoursSincerely(draft[0].introductionData.yoursSincerely);
        setAboutCompany(draft[0].introductionData.aboutCompany);
        setWhyCompany(draft[0].introductionData.whyCompany);
      }
    }
  }, [draft]);

  const handleDearClientChange = (e) => setDearClient(e.target.value)
  const handleAboutCompanyChange = (e) => setAboutCompany(e.target.value)
  const handleWhyCompanyChange = (e) => setWhyCompany(e.target.value)
  return (
    <>
      <div className={`${slideAnimation} flex flex-col  md:flex-row md:items-stretch`}> 
      
     <div className="flex justify-center">
      <button
        className="bg-blue-300 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 hover:text-white transition-colors duration-300"
        onClick={handleSubmit}
        disabled={save === 'Saved!'}
      >
       {save}
      </button>
    </div>
        <div className="md:w-[55%] min-h-80 bg-black text-white p-4 md:8">
          <h3
            className="text-sm font-semibold"
            contentEditable
            onBlur={(e) => setClientName(e.target.textContent)}
            suppressContentEditableWarning={true}
          >
            {clientName}
          </h3>
          <br/>
          <p contentEditable onBlur={(e) => setDearClient(e.target.textContent)} suppressContentEditableWarning={true}>
            <textarea
              className="w-full color-black bg-black text-sm" 
              rows={15}   
              value={dearClient}
              onChange={handleDearClientChange}
            />
          
          </p>
          <p className="mt-4 text-sm">
          Kind regards,</p>
          <p className="mt-4 text-sm" contentEditable onBlur={(e) => setYoursSincerely(e.target.textContent)} suppressContentEditableWarning={true}>
          {yoursSincerely.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
          </p>
        </div>
        <div className="md:w-[45%] min-h-[320px] bg-[url('/public/assets/images/aparna-ceo-image.png')] bg-no-repeat bg-cover">
         {/*  <label htmlFor="backgroundImageInput" className="sr-only">
            Upload Background Image
          </label>
          <input
            type="file"
            id="backgroundImageInput"
            accept="image/*"
            onChange={handleBackgroundImageChange}
            style={{ display: 'none' }}
          />
          <div
            className="w-full h-full cursor-pointer"
            style={{ 
              backgroundImage: `url(${backgroundImage})`, 
              backgroundSize: "cover", // or "contain" to fit the image
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            onClick={() => document.getElementById('backgroundImageInput').click()}
          ></div> */}
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:items-stretch bg-white border-b-[20px] border-red-600 py-2">
        <div className="md:w-[55%] min-h-[400px] px-4 sm:px-10 mt-4">
          <h2 className="text-2xl">About Alphabet Factory</h2>
          <textarea
              className="w-full text-sm" 
              rows={18}   
              value={aboutCompany}
              onChange={handleAboutCompanyChange}
            />
         
        </div>
        <div className=" md:w-[45%] min-h-[400px] px-4 sm:px-10 mt-4">
          <h2 className="text-2xl">Why Choose Us?</h2>
          <textarea
              className="w-full text-sm" 
              rows={18}   
              value={whyCompany}
              onChange={handleWhyCompanyChange}
            />
          
        </div>
        
      </div>
    </>
  );
};

export default Introduction;
