import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";

import LoginPage from "./pages/login";
import SignupPage from "./pages/signUp";
import ProposalsPage from "./pages/proposals_admin"
import ProposalPage from "./pages/proposals";
import Proposals from "./pages/proposals/[id]"
import ForgetPassswordPage from "./pages/forgetPassword";
import PersistLogin from './features/auth/PresistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'
import Prefetch from "./features/auth/Prefetch";
import ResetPasswordPage from "./pages/resetPassword";
import { useEffect } from "react";
import Proposal from "./pages/proposals_admin/[id]";
import NotFoundPage from "./pages/notFoundPage";

function App () {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  
  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);
  useEffect(() => {
    document.title = 'Alphabet Factory Inc | Business Suite';
  }, []);

  useEffect(() => {
    
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/signup":
          title = "";
          metaDescription = "";
          break;
      case "/proposals":
        title = "";
        metaDescription = "";
        break;
      case "/approvals":
          title = "";
          metaDescription = "";
          break;
      case "/login":
        title = "";
        metaDescription = "";
        break;
      case "/forgot-password":
            title = "";
            metaDescription = "";
            break;
      case "/resetPassword":
              title = "";
              metaDescription = "";
              break;
        
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);
  return (
  <Routes>
    <Route path="/" element={<LoginPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/signup" element={<SignupPage />} />
    <Route path="/forgot-password" element={<ForgetPassswordPage />} />
    <Route path="/resetPassword/:token/:id" element={<ResetPasswordPage />} />
    
    <Route path="/proposal" >   
        <Route index element={<ProposalPage />} />
        <Route path=":clientName/:id" element={<Proposals />} />
    </Route>
    <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="/proposals" >   
             <Route index element={<ProposalsPage />} />
             <Route path=":id" element={<Proposal />} />
          </Route>
              
      </Route>
      </Route>    
      {/* Protected Customer Routes */}
    <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
          <Route element={<Prefetch />}>
          
          </Route>
          </Route>
    </Route>
     {/* 404 Page */}
     <Route path="*" element={<NotFoundPage />} />
   
  </Routes>
  );
};

export default App;
