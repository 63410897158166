import{
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const draftsAdapter = createEntityAdapter({})

const initialState = draftsAdapter.getInitialState()

export const DraftsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder =>({
        getDrafts: builder.query({
            query: ()=> '/drafts',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
            keepUnusedDataFor: 5,
            transformResponse: responseData =>{
                const loadedDrafts = responseData.map(draft => {
                    draft.id = draft._id
                    return draft
                });
                return draftsAdapter.setAll(initialState, loadedDrafts)
            },
            providesTags: (result, error, arg) =>{
                if(result?.ids){
                    return [
                        {type: 'Draft', id: 'List'},
                        ...result.ids.map(id=> ({type: 'Draft', id}))
                    ]
                }else return [{type: 'Draft', id: 'LIST'}]
            }

        }),
        createNewDraft: builder.mutation({
            query: credentials => ({
                url: '/drafts',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        editDraft: builder.mutation({
            query: credentials => ({
                url: '/drafts',
                method: 'PATCH',
                body: { ...credentials }
            })
        }),
        deleteDraft: builder.mutation({
            query: credentials => ({
                url: '/drafts',
                method: 'DELETE',
                body: { ...credentials }
            })
        }),
    })
})
 export const {
    useGetDraftsQuery,
    useCreateNewDraftMutation,
    useEditDraftMutation,
    useDeleteDraftMutation,
    
 } = DraftsApiSlice

 export const selectDraftsResult =  DraftsApiSlice.endpoints.getDrafts.select()

 const selectDraftsData = createSelector(
    selectDraftsResult,
    draftsResult => draftsResult.data
 )

 export const {
    selectAll: selectAllDrafts,
    selectById: selectDraftsById,
    selectIds: selectDraftIds
 } = draftsAdapter.getSelectors(state => selectDraftsData(state) ?? initialState)

 export const selectDraftsByProposalId = createSelector(
    selectAllDrafts,
    (_, proposalId) => proposalId,
    (drafts, proposalId) => drafts.filter(draft => draft.proposalId === proposalId)
  );