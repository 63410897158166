import{
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const resetsAdapter = createEntityAdapter({})

const initialState = resetsAdapter.getInitialState()

export const resetApiSlice = apiSlice.injectEndpoints({
    endpoints: builder =>({
        checkOTP: builder.mutation({
            query: credentials => ({
                url: '/resetPassword/checkOTP',
                method: 'POST',
                body: { ...credentials }
            })
        }),
        
        resetPassword: builder.mutation({
            query: credentials => ({
                url: '/api/v1/auth/resetPassword',
                method: 'POST',
                body: { ...credentials }
            })
        })
        
    })
})
 export const {
    useResetPasswordMutation,
    useCheckOTPMutation
 } = resetApiSlice

 