import React, { useState, useEffect } from 'react';
import { useEditProposalMutation } from '../../../features/proposals/ProposalsApiSlice';
import { useParams } from 'react-router-dom';
import { selectProposalsByProposalId } from '../../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetProposalsQuery } from '../../../features/proposals/ProposalsApiSlice';
import { handleConvertToPdf } from '../../../global';

const PlanOfAction = ({slideAnimation}) => {

   
  const { id } = useParams();
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  console.log(id)
  const proposalId = id
  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    console.log(proposal)
  }
  
  const [heading, setHeading] = useState('Development Approach');
  const [subHeading, setSubHeading] = useState(
    'To make our collaboration a great success, we propose the following action plan. Of course, we will refine this further as soon as we get started. All your feedback is indispensable for a perfect result.'
  );

  const [actionPlan, setActionPlan] = useState([
    {
      phase: 'Preparation',
      information:
        'The final schedule is drawn up and the most important requirements are prepared',
      who: 'Alphabet Factory',
      week: '1',
    },
  ]);

  const addNewRow = () => {
    setActionPlan((prevActionPlan) => [
      ...prevActionPlan,
      {
        phase: '',
        information: '',
        who: '',
        week: '',
      },
    ]);
  };

  const removeRow = (index) => {
    setActionPlan((prevActionPlan) => prevActionPlan.filter((item, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    setActionPlan((prevActionPlan) =>
      prevActionPlan.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  const [createProposal, {isLoading}]= useEditProposalMutation()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createProposal({
        planofactionData: {
          heading,
          subHeading,
          actionPlan, 
        }, proposalId
      }).unwrap();
      console.log('Proposal created successfully', response);
    } catch (error) {
      console.error('Error creating proposal:', error);
    }
  };

  useEffect(() => {
    if (proposal) {
      console.log("proposal:", proposal);

      if (proposal.length > 0 && proposal[0].planofactionData) {
        // Access the clientName property only if planofactionData exists
        setHeading(proposal[0].planofactionData.heading);
        setSubHeading(proposal[0].planofactionData.subHeading);
        setActionPlan(proposal[0].planofactionData.actionPlan);
       
      }
    }
  }, [proposal]);

  useEffect(() => {
    // Function to call handlePdfConversion when the component mounts
    
    if (proposal) {
        
        if(proposal.length > 0){
              if(proposal[0].planofactionData.actionPlan===actionPlan){
                handleConvertToPdf('plan-pdf-content');
          }
        }
      }
    
   
    //convertToPdfOnMount(); // Call the function when the component mounts
  }, [proposal, actionPlan]); // The empty dependency array ensures it runs only on mount

  return (
    <div id="plan-pdf-content" className={`${slideAnimation}`}>
      <div className="h-auto bg-white p-3 md:p-8">
        <h1
          className="text-2xl font-semibold"
          
          onBlur={(e) => setHeading(e.target.textContent)}
          
        >
          {heading}
        </h1>
        <p
          className="text-sm mt-4 text-justify"
          
          onBlur={(e) => setSubHeading(e.target.textContent)}
          
        >
          {subHeading}
        </p>
        <div className="overflow-auto">
          <table className="w-full mt-4">
            <thead className="bg-black text-white h-8 text-left">
              <tr>
                <th className="w-[20%] px-4">Milestone</th>
                <th className="w-[50%] px-4">Description</th>
                <th className="w-[20%] px-4">Deliverables</th>
                <th className="w-[10%] px-4">Time</th>
                <th className="w-[10%] px-4"></th>
              </tr>
            </thead>

            <tbody className="text-sm">
              {actionPlan.map((row, index) => (
                <tr key={index} className="h-8 even:bg-gray-200">
                  <td className="px-4">
                  {row.phase.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
                    
                  </td>
                  <td className="px-4">
                    {row.information.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
                    
                  </td>
                  <td className="px-4">
                  {row.who.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
                  </td>
                  <td className="px-4">
                  {row.week.split('\n').map((line, index) => <span key={index}>{line}<br /></span>)}
                  </td>
                  <td className='px-4'>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-end mt-4">
          
        </div>
      </div>
     
    </div>
  );
};

export default PlanOfAction;
