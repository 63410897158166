import React, { useState, useEffect } from 'react';
import { useEditProposalMutation } from '../../../features/proposals/ProposalsApiSlice';
import { useParams } from 'react-router-dom';
import { selectProposalsByProposalId } from '../../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetProposalsQuery } from '../../../features/proposals/ProposalsApiSlice';

const Contact = ({slideAnimation}) => {

  const { id } = useParams();
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  console.log(id)
  const proposalId = id
  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    console.log(proposal)
  }

  const [title, setTitle] = useState('How can we help you out?');
  const [generalDescription, setGeneralDescription] = useState('We operate in a culture built on trust and we believe this can only be achieved through communication and experienced support. We guarantee at Alphabet Factory you will always talk to a human! Get in touch with us and we will assure you to make your dream a reality.');
  const [generalPhone, setGeneralPhone] = useState('+91 .120.420.5362');
  const [generalEmail, setGeneralEmail] = useState('sales@alphabetfactory.in');
  const [contact1Name, setContact1Name] = useState('Kalyan Gali');
  const [contact1Des, setContact1Des] = useState('Director Delivery & Research')
  const [contact1Description, setContact1Description] = useState('A multi-award-winning creative dude, with a passion for details. Kalyan has spent the last 18 years creating, designing, and directing work for some of the world’s most exciting and forward-thinking brands, such as HCL, Aristocrat, Bwin, GM, Netflix, and Xebia.'
  );
  const [contact1Phone, setContact1Phone] = useState('+91 .93.1234.2222');
  const [contact1Email, setContact1Email] = useState('kalyan@alphabetfactory.in');
  const [contact2Name, setContact2Name] = useState('Aparna Chugh');
  const [contact2Des, setContact2Des] = useState('Director Content Strategy')
  const [contact2Description, setContact2Description] = useState('A hands-on content expert with more than 18 years of experience. Aparna has been responsible for bringing concepts to life for clients such as Phillips, Xebia, Dasa, PMI, CCC, Sony, and many others. Her work has been recognised by India 5000, the MSME council, and JCI Lions.'
  );
  const [contact2Phone, setContact2Phone] = useState('+91 .93.1234.3333');
  const [contact2Email, setContact2Email] = useState('aparna@alphabetfactory.in');
  const [createProposal, {isLoading}]= useEditProposalMutation()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

     
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createProposal({
        contactsData: {
          title,
          generalDescription,
          generalPhone,
          generalEmail,
          contact1Name,
          contact1Description,
          contact1Phone,
          contact1Email,
          contact2Name,
          contact2Description,
          contact2Phone,
          contact2Email,

        }, proposalId
      }).unwrap();
      console.log('Proposal created successfully', response);
    } catch (error) {
      console.error('Error creating proposal:', error);
    }
  };

  useEffect(() => {
    if (proposal) {
      console.log("proposal:", proposal);

      if (proposal.length > 0 && proposal[0].contactsData) {
        // Access the title property only if contactsData exists
        setTitle(proposal[0].contactsData.title);
        setGeneralDescription(proposal[0].contactsData.generalDescription);
        setGeneralPhone(proposal[0].contactsData.generalPhone);
        setGeneralEmail(proposal[0].contactsData.generalEmail);
        setContact1Email(proposal[0].contactsData.contact1Email);
        setContact1Name(proposal[0].contactsData.contact1Name);
        setContact1Phone(proposal[0].contactsData.contact1Phone);
        setContact1Description(proposal[0].contactsData.contact1Description);
        setContact2Email(proposal[0].contactsData.contact2Email);
        setContact2Name(proposal[0].contactsData.contact2Name);
        setContact2Phone(proposal[0].contactsData.contact2Phone);
        setContact2Description(proposal[0].contactsData.contact2Description);
        setContact1Des(proposal[0].contactsData.contact1Des);
        setContact2Des(proposal[0].contactsData.contact2Des);
      }
    }
  }, [proposal]);
  return (
    <div className={`${slideAnimation} bg-white h-full min-h-[786px] max-w-3xl mx-auto lg:max-w-full`}>
      <div className="bg-white h-full min-h-[786px] max-w-3xl mx-auto lg:max-w-full">
        <div className="flex flex-col lg:flex-row lg:items-stretch lg:h-[393px]">
          <div className="lg:w-2/6 min-h-[320px] lg:h-full  px-6 py-8 bg-gray-200 ">
           
            <h2 className="h-10 text-3xl font-semibold"  onBlur={(e) => setTitle(e.target.textContent)} >
              {title}
            </h2>
            
            <p className="my-4 text-sm py-5"  onBlur={(e) => setGeneralDescription(e.target.textContent)} >
              {generalDescription}
            </p>
            <strong>Direct Sales Phone:</strong>
            <p className="text-sm"  onBlur={(e) => setGeneralPhone(e.target.textContent)} >
              {generalPhone}
            </p>

            <strong className="mt-4 block">Direct Sales Email:</strong>
            <p className="text-sm"  onBlur={(e) => setGeneralEmail(e.target.textContent)} >
              {generalEmail}
            </p>
          </div>
          <div className="lg:w-2/6 min-h-[320px] lg;min-h-full px-6 py-8">
            
            <h4 className="text-2xl text-black font-semibold" onBlur={(e) => setContact1Name(e.target.textContent)} >
              {contact1Name}</h4>
            <h2 className="text-1xl text-black" onBlur={(e) => setContact1Des(e.target.textContent)}      >
              {contact1Des}</h2>
            <p className="my-2 text-sm"  onBlur={(e) => setContact1Description(e.target.textContent)} >
              {contact1Description}
            </p>
            <strong>Direct Phone:</strong>
            <p className="text-sm"  onBlur={(e) => setContact1Phone(e.target.textContent)} >
              {contact1Phone}
            </p>

            <strong className="mt-4 block">Direct Email:</strong>
            <p className="text-sm"  onBlur={(e) => setContact1Email(e.target.textContent)} >
              {contact1Email}
            </p>
          </div>

          <div className="lg:w-2/6 min-h-[320px] lg;min-h-full px-6 py-8">
          <h4 className="text-2xl text-black font-semibold" onBlur={(e) => setContact2Name(e.target.textContent)}      >
              {contact2Name}</h4>
              <h2 className="text-1xl text-black " onBlur={(e) => setContact2Des(e.target.textContent)}      >
              {contact2Des}</h2>
            <p className="my-2 text-sm"  onBlur={(e) => setContact2Description(e.target.textContent)} >
              {contact2Description}
            </p>
            <strong>Direct Phone:</strong>
            <p className="text-sm"  onBlur={(e) => setContact2Phone(e.target.textContent)} >
              {contact2Phone}
            </p>

            <strong className="mt-4 block">Direct Email:</strong>
            <p className="text-sm"  onBlur={(e) => setContact2Email(e.target.textContent)} >
              {contact2Email}
            </p>
          </div>
        
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center lg:h-[393px]">
          <div className="lg:w-2/6 min-h-[320px] lg:min-h-full bg-[url('/public/assets/images/backgroundImage.jpg')] bg-no-repeat bg-cover"></div>
          <div className="lg:w-2/6 min-h-[320px] lg:min-h-full bg-[url('/public/assets/images/backgroundImage.jpg')] bg-no-repeat bg-cover"></div>
          <div className="lg:w-2/6 min-h-[320px] lg:min-h-full bg-[url('/public/assets/images/backgroundImage.jpg')] bg-no-repeat bg-cover"></div>
        </div>
      </div>
      
    </div>
  );
};

export default Contact;
