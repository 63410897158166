import { useRef } from "react";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useEditProposalMutation } from "../../../features/proposals/ProposalsApiSlice";
import { useGetProposalsQuery } from '../../../features/proposals/ProposalsApiSlice';
import { selectProposalsByProposalId } from '../../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';

const Question = ({ onClose }) => {
  const userRef = useRef();
  
  const [question, setQuestion]= useState('')
  
  const { id } = useParams();
  const [date, setDate] = useState()
  
  const proposalId = id
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content
  const[isSaved, setSaved] = useState('Send Now')

  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  console.log(id)

  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    //console.log(proposal)
  }
  const [createProposal, {isLoading}]= useEditProposalMutation()
  const [status, setStatus] = useState('Question Asked!')
  const [isSend, setSend] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createProposal({
        questionData: {
          question,
          date,
        }, proposalId, status
      }).unwrap();
      console.log('Proposal edited successfully', response);
      setSaved('Saved!')
      setSend(true)
    } catch (error) {
      console.error('Error creating proposal:', error);
    }
  };
  const [moreQ, setMore]= useState("Have some queries on the proposal? ")
  useEffect(() => {
    setDate(new Date().toLocaleString())
    if (proposal) {
      //console.log("proposal:", proposal);

      if (proposal.length > 0 && proposal[0].coverData) {
        // Access the clientName property only if investmentData exists
        //setTextContent(proposal[0].investmentData.textContent);
        //setTableContent(proposal[0].investmentData.tableContent);
       
        if(proposal[0].questionData){
          setMore("Have more queries on the proposal? Ask away!")
        }
      }
    }
  }, [proposal]);

  const handleQuestionChange = (e) => setQuestion(e.target.value)
  return (
    <div className="relative md:w-[600px] ml-[-0.5rem] p-4 md:p-8 rounded-2xl shadow-xl bg-white">
      {!isSend ? (
          <>
          <div className="flex items-start justify-between">
        <h2 className="mb-2  text-2xl font-semibold">
          {moreQ}
        </h2>
        <button className="p-2  hover:text-red-600" onClick={onClose}>
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>
      <label className="w-full md:w-1/2">
        <p className="mb-2">Sure, let’s talk! Please add your queries in the below space and click on send now. We will make sure they are addressed. 100% Guaranteed.</p>
        <textarea
          className="p-2 mb-2 w-full rounded-md bg-gray-200 border-2 border-gray-300 focus:outline-none"
          placeholder="Please write your question..."
          cols={20}
          rows={5}
          ref={userRef}
          value={question}
          onChange={handleQuestionChange}
          
        />
      </label>
      <button className="py-2 px-6 text-md rounded-md font-semibold text-white bg-black uppercase hover:bg-green-600 transition-colors duration-300"
        onClick={handleSubmit}
       
      >
        {isSaved}
      </button>
          </>
      ): (
        <>
        <div className="flex items-start justify-between">
        <h2 className="mb-2  text-2xl font-semibold">
          Have some queries on the proposal? 
        </h2>
        <button className="p-2  hover:text-red-600" onClick={onClose}>
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>
       <p className="text-justifyb mt-2">
            <label className="text-sm">
              
            “Thanks, for your valuable query. It was sent to the right person and we will get back the earliest possible.”

            </label>
          </p>
        </>
      )}
    </div>
  );
};

export default Question;
