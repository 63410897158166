import { useNavigate } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react';
import { useResetRequestMutation } from '../../features/auth/authApiSlice';
import Loader from '../Loader';

const ForgetPassCard = () => {
  const navigate = useNavigate()

  const userRef = useRef()
  const errRef = useRef()
  const [email, setEmail] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const [resetRequest, { isLoading }] = useResetRequestMutation()
  const [submitted, setSubmitted] = useState(false);
  useEffect(()=>{
    userRef.current.focus()
  }, [])

  useEffect(()=>{
    setErrMsg('');
  }, [email])

  const handleSubmit = async (e)=>{
    e.preventDefault()
    try{
       await resetRequest({ email}).unwrap()
       
       setSubmitted(true)
      
    }catch(err) {
      if(!err.status){
         setErrMsg('No Server Response')

      }else if(err.status === 400){
         setErrMsg('Missing Email or Password')
      } else if(err.status === 404){
         setErrMsg('Email is not registered!')
      } 
      else if(err.status === 402){
       setErrMsg('Incorrect Password')
     } else{
         setErrMsg(err.data?.message);
      }
      errRef.current.focus();
      console.log(errMsg)
    }
 }
  const onLoginClick= ()=>{
    navigate('/login')
  }
  
 
  const errClass = errMsg ? "errmsg" : "offscreen"
  if(isLoading) return  <Loader />

  const handleEmailInput= (e) => setEmail(e.target.value)
  return (
    <div className="py-5 px-6 bg-white w-[95%] sm:w-[75%] lg:max-w-[400px] m-auto rounded-[2rem] ">
    
    {submitted ? (
      <>
      <h1 className="mb-6 font-semibold text-4xl text-center">
        Forget Password
      </h1>
        <p className="text-center mt-8 text-lg font-semibold">
          We've send a link to your email {email} to reset your password
        </p>
        </>
      ) : (
        <>
      <h1 className="mb-6 font-semibold text-4xl text-center">
        Forget Password
      </h1>
      <p ref={errRef} className="mb-2" aria-live="assertive">{errMsg}</p>
      <form onSubmit={handleSubmit}>
        
        <div className="mb-2">
          <label className="font-semibold inline-block mb-2" htmlFor="username">
            Email
          </label>
          <input
            type="email"
            id="username"
            placeholder="Enter your email"
            className="bg-gray-100 h-10 w-full border-2 border-gray-300 rounded-lg px-4 focus:outline-none"
            ref={userRef}
            value={email}
            onChange={handleEmailInput}
          />
        </div>
        <div className="mb-6 text-end text-sm"></div>

        <div className="text-center">
          <button className="py-2 px-8 rounded-full text-lg font-semibold text-white bg-black uppercase">
            Submit
          </button>
        </div>
      </form>

      <div className="text-center">
        <p className="mt-2 font-semibold">OR</p>
        <a className="text-red-600 font-semibold cursor-pointer uppercase" onClick={onLoginClick}>
          Login?
        </a>
        </div>
       </>
      )}
    </div>
    
  );
};

export default ForgetPassCard;
