import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const pdf = new jsPDF({
        orientation: 'landscape',
        format: 'a4',
    });
    
const scannedContentSet = new Set();

const handleConvertToPdf = async (content) => {
    console.log(`Trying to scan ${content}`);
    if (!scannedContentSet.has(content)) {
        console.log(`Scanning ${content}`);
        scannedContentSet.add(content);
        const pageContent = document.getElementById(content);
        const canvas = await html2canvas(pageContent, { scrollY: -window.scrollY });
        const imageData = canvas.toDataURL('image/png');
        if(content==='cover-pdf-content'){
                pdf.addImage(imageData, 'PNG', 0, 0, 300, 222);
        }
        if(content==='introduction-pdf-content'){
                pdf.addImage(imageData, 'PNG', 0, 0, 300, 222);
        }
        if(content==='plan-pdf-content'){
                pdf.addImage(imageData, 'PNG', 0, 0, 300, 210);
        }
        if(content==='investment-pdf-content'){
                pdf.addImage(imageData, 'PNG', 0, 0, 300, 222);
        }
        pdf.addPage();
        
        
        console.log(`${content} added to scannedContentSet`);
    } else {
        console.log(`${content} already scanned`);
    }
};

const downloadPDF = () =>{
        pdf.save('proposal_slides.pdf');
        window.location.reload()
}

function getscannedItem () {
        return scannedContentSet
}


let adminName
let adminRole
let user
let userName
let userSubscription
let proposalno=0
let draftno


function setAdminDetails(Name, Role){
        adminName=Name
        adminRole=Role
}
function getAdminName(){
    return adminName
}
function getAdminRole(){
    return adminRole
}

function setuserDetails(Name, Subscription, User){
        userName=Name
        userSubscription=Subscription
        user= User
}
function getuserName(){
        return userName
}
function getuser(){
        return user
}
function getuserSubscription(){
        return userSubscription
}

function setProposalno(){
        proposalno=0
}

function getProposalno(){
        proposalno++
        return proposalno
}
function setDraftno(){
        draftno=0
}

function getDraftno(){
        draftno++
        return draftno
}



export{
    setAdminDetails,
    setuserDetails,
    getAdminName,
    getAdminRole,
    getuserName,
    getuserSubscription,
    getProposalno,
    setProposalno,
    getuser,
    getDraftno,
    setDraftno,
    handleConvertToPdf,
    downloadPDF,
    getscannedItem
}