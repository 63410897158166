import React, { useState, useEffect } from 'react';
import { useEditDraftMutation } from '../../../features/drafts/DraftApiSlice';
import { useParams } from 'react-router-dom';
import { selectDraftsByProposalId } from '../../../features/drafts/DraftApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetDraftsQuery } from '../../../features/drafts/DraftApiSlice';

const Proposal = ({slideAnimation , submission, setSubmission}) => {
  const { id } = useParams();
  const {
    data: drafts,
    isSuccess,
    isError,
    error
  } = useGetDraftsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = drafts
    
  }
  console.log(id)
  const proposalId = id
  const draft = useSelector((state) => selectDraftsByProposalId(state, proposalId));

  

  const [introductionH, setIntroductionH]= useState("Requirement Analysis")
  const [introduction, setIntroduction] = useState(
    `What we can do for you depends on the service you want to purchase.
     First of all, we sit down with you to discuss your wishes and goals.
     Are these clear and do we understand each other well? Then we will
     look for the right advisor to help you. We will link you to an expert
     and experienced advisor who will help you with your request for help.`
  );


  const [personalContactH, setPersonalContactH] = useState("Personal Contact") 
  const [personalContact, setPersonalContact] = useState(
    `You will receive a direct contact, so you can count on a fast service
     at all times and we are always ready for you. The number of hours for
     which the expert advisor has been hired can be found on the quotation.
     You will receive an overview of the hours worked per month, so you
     will not be faced with surprises later.`
  );
  const [concreteSolutionH, setConcreteSolutionH]= useState("Concrete Solution")  
  const [concreteSolution, setConcreteSolution] = useState(
    `Your advisor gives you advice, concrete and feasible solutions and
     also takes care of the implementation. In the meantime and at the end
     of the services provided, an evaluation takes place, so you can
     indicate whether you are satisfied with the service or whether you
     would like to see changes. We adapt the service to your wishes.`
  );
  const [exceedingExpectationsH, setExceedingExpectationsH] = useState("Exceeding Expectations")  
  const [exceedingExpectations, setExceedingExpectations] = useState(
    `With us by your side, you don't have to lie awake all night, we take
     care of you and give you the best possible advice or help. The driven
     consultants ensure that the project is successfully completed and
     together with you realize results that exceed expectations.`
  );
  
  const [createDraft, {isLoading}]= useEditDraftMutation()

  const [save, setSaved]= useState('Save')

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createDraft({
        proposalData: {
          introduction,
          introductionH,
          personalContact,
          personalContactH,
          concreteSolution,
          concreteSolutionH,
          exceedingExpectations,
          exceedingExpectationsH    
        }, proposalId
      }).unwrap();
      console.log('Draft created successfully', response);
      setSaved('Saved!')
      window.location.reload()
    } catch (error) {
      console.error('Error creating draft:', error);
    }
  };

  useEffect(() => {
    if (draft) {
      console.log("draft:", draft);

      if (draft.length > 0 && draft[0].proposalData) {
        // Access 
        setIntroduction(draft[0].proposalData.introduction);
        setIntroductionH(draft[0].proposalData.introductionH);
        setPersonalContact(draft[0].proposalData.personalContact);
        setPersonalContactH(draft[0].proposalData.personalContactH);
        setConcreteSolution(draft[0].proposalData.concreteSolution);
        setConcreteSolutionH(draft[0].proposalData.concreteSolutionH);
        setExceedingExpectations(draft[0].proposalData.exceedingExpectations);
        setExceedingExpectationsH(draft[0].proposalData.exceedingExpectationsH);
      }
    }
  }, [draft]);
  
  return (
    <div id="proposal-pdf-content" className={`${slideAnimation} flex flex-col md:flex-row items-stretch min-h-[768px]`}>      <div className="md:w-1/2 bg-white p-4 md:p-8 flex flex-col gap-4">
        <div className="relative z-10"> {/* Increase the z-index to a higher value */}
         <button
           className="bg-blue-300 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 hover:text-white transition-colors duration-300 absolute top-0 right-0"
           onClick={handleSubmit}
           disabled={save === 'Saved!'}
         >
          {save}
         </button>
      </div>
        <h3 className="text-3xl font-bold"
            contentEditable
            onBlur={(e) => setIntroductionH(e.target.textContent)}
        >{introductionH}</h3>
        <p
          className="text-sm"
          contentEditable
          onBlur={(e) => setIntroduction(e.target.textContent)}
          dangerouslySetInnerHTML={{ __html: introduction }}
        />

        <h4 className="text-2xl text-black font-bold"
          contentEditable
          onBlur={(e) => setPersonalContactH(e.target.textContent)}
          >{personalContactH}</h4>
        <p
           className="text-sm"
          contentEditable
          onBlur={(e) => setPersonalContact(e.target.textContent)}
          dangerouslySetInnerHTML={{ __html: personalContact }}
        />

        <h4 className="text-2xl text-black font-bold"
            contentEditable
            onBlur={(e) => setConcreteSolutionH(e.target.textContent)}
        >{concreteSolutionH}</h4>
        <p
          className="text-sm"
          contentEditable
          onBlur={(e) => setConcreteSolution(e.target.textContent)}
          dangerouslySetInnerHTML={{ __html: concreteSolution }}
        />

        <h4 className="text-2xl text-black font-bold"
        contentEditable
        onBlur={(e) => setExceedingExpectationsH(e.target.textContent)}
        >{exceedingExpectationsH}</h4>
        <p
           className="text-sm"
          contentEditable
          onBlur={(e) => setExceedingExpectations(e.target.textContent)}
          dangerouslySetInnerHTML={{ __html: exceedingExpectations }}
        />
      </div>
      <div className="md:w-1/2 min-h-[420px] bg-[url('/public/assets/images/backgroundImage.jpg')] bg-no-repeat bg-cover"></div>
      
    </div>
  );
};

export default Proposal;
