import { useGetDraftsQuery } from "../../../features/drafts/DraftApiSlice";
import Draft from "../../../features/drafts/Draft";
import Loader from "../../Loader";
import { setDraftno } from "../../../global";

const DraftTable = ({ onEdit, onRemove }) => {
  const {
    data: drafts,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetDraftsQuery()
  let content
    
  if (isLoading) content = <><p>Loading....</p></>

  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      setDraftno()
      const { ids } = drafts
      const tableContent = ids?.length?ids.map(draftId => <Draft key={draftId} userId={draftId}/>) : null
      content = (
        <table className="w-full text-sm border-y-separate border-spacing-y-4 text-left" style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
              <thead>
                    <tr>
                      <th className="pb-4 text-center">Draft No.</th>
                      <th className="pb-4">Name</th>
                      <th className="pb-4">Email</th>
                      <th className="pb-4">Phone</th>
                      <th className="pb-4">GST</th>
                      <th className="pb-4">Created on</th>
                      
                    </tr>
              </thead>
              <tbody className="text-left ]">
                  {tableContent}
              </tbody>
          </table>
      )
      
  }
  return (
    <>
      <h2 className="text-xl font-bold py-4">Drafts</h2>
      <div className="overflow-auto">
        {content}
      </div>
    </>
  );
};

export default DraftTable;
