import React, { useState, useEffect } from 'react';
import { useEditDraftMutation } from '../../../features/drafts/DraftApiSlice';
import { useParams } from 'react-router-dom';
import { selectDraftsByProposalId } from '../../../features/drafts/DraftApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetDraftsQuery } from '../../../features/drafts/DraftApiSlice';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Investment = ({ slideAnimation, submission, setSubmission }) => {
  const { id } = useParams();
  const {
    data: drafts,
    isSuccess,
    isError,
    error
  } = useGetDraftsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = drafts
    
  }
  console.log(id)
  const proposalId = id
  const draft = useSelector((state) => selectDraftsByProposalId(state, proposalId));

  if(draft){
    console.log(draft)
  }
  
  const [textContent, setTextContent] = useState(`
  In the realm of commercials, we go beyond crafting mere advertisements – we sculpt captivating narratives that resonate and drive impact. Our approach blends artistic finesse with strategic acumen, ensuring your brand's essence shines through every frame.

With a keen understanding of market dynamics, we curate commercials that stand out amidst the noise. Our commitment to excellence is matched only by our dedication to delivering optimal value. From storyboard to screen, we meticulously manage resources, maximizing your returns on investment.

`);


const [tableContent, setTableContent] = useState([
  {
    description: "MileStone 1",
    price: 20,
    subtotal: "15000",
  },
  {
    description: "MileStone 2",
    
    price: 20,
    subtotal: "15000",
  },
  {
    description: "MileStone 3",
    
    price: 20,
    subtotal: "15000",
  },
  {
    description: "MileStone 4",
    price: 20,
    subtotal: "15000",
  },
  {
    description: "MileStone 5",
    price: 20,
    subtotal: "15000",
  },
 
]);
const addNewRow = () => {
  setTableContent((prevTableContent) => [
    ...prevTableContent,
    {
      description: '',
      price: '',
      subtotal: ''
      
    },
  ]);
};

const removeRow = (index) => {
  setTableContent((prevTableContent) => prevTableContent.filter((item, i) => i !== index));
};
/* // Function to calculate the subtotal of services and discount
const calculateSubtotal = () => {
  const serviceRow = tableContent.find((row) => row.description === "Service");
  const discountRow = tableContent.find((row) => row.description === "Discount");
  const serviceCount = serviceRow.count;
  const servicePrice = serviceRow.price;
  const discountPercentage = discountRow.price / 100;

  let subtotal = serviceCount * servicePrice;
  let discount = serviceCount * servicePrice * discountPercentage;

  tableContent[2].subtotal = subtotal - discount;
  tableContent[1].subtotal = -discount;
  tableContent[0].subtotal = subtotal
};

// Function to calculate the total including extra services
const calculateTotal = () => {
  calculateSubtotal();

  const serviceRow = tableContent.find((row) => row.description === "Service");
  const serviceCount = serviceRow.count;
  const servicePrice = serviceRow.price;

  let total = tableContent[2].subtotal;

  tableContent.slice(4, 6).forEach((row) => {
    const extraServiceCount = row.count;
    const extraServicePrice = row.price;
    total += extraServiceCount * extraServicePrice * serviceCount;
  });
  // Calculate subtotal for "Service A" and "Service B"
  const serviceARow = tableContent.find((row) => row.description === "Service A");
  const serviceBRow = tableContent.find((row) => row.description === "Service B");
  const serviceACount = serviceARow.count;
  const serviceAPrice = serviceARow.price;
  const serviceBCount = serviceBRow.count;
  const serviceBPrice = serviceBRow.price;
  const totalA= serviceACount * serviceAPrice
  tableContent[4].subtotal= totalA
  const totalB= serviceBCount * serviceBPrice
  tableContent[5].subtotal= totalB
  const SubtotalS= serviceACount * serviceAPrice + serviceBCount * serviceBPrice
  tableContent[6].subtotal= SubtotalS
  total += (serviceACount * serviceAPrice + serviceBCount * serviceBPrice) * serviceCount;
  console.log('Total', total)
  tableContent[7].subtotal = total;

  setTableContent([...tableContent]);
  };

  useEffect(() => {
    calculateTotal();
  }, [tableContent]); */

  const handleTextContentChange = (event) => {
    setTextContent(event.target.textContent);
  };

  const handleTableContentChange = (event, index, field) => {
    const updatedTableContent = [...tableContent];
 
        updatedTableContent[index][field] = event.target.value;
        setTableContent(updatedTableContent);
      
  };

  const handleCheckboxChange = (index) => {
    const updatedTableContent = [...tableContent];
  
    // Check if the index is within the valid range of the tableContent array
    if (index >= 0 && index < updatedTableContent.length) {
      // Check if the 'count' property exists for the element at the given index
      if (updatedTableContent[index].hasOwnProperty('count')) {
        updatedTableContent[index].count = updatedTableContent[index].count === 0 ? 1 : 0;
        setTableContent(updatedTableContent);
      } else {
        // Handle the case where the 'count' property is missing for the element
        console.error(`'count' property is missing for the element at index ${index}`);
      }
    } else {
      // Handle the case where the index is out of range
      console.error(`Invalid index: ${index}`);
    }
  };
  const [termsAndConditions, setTermsAndConditions] = useState(
    `- 30 Days maintenance work free for all the Content, functionality we developed and delivered.\n` +
    `- A delay in processing invoice beyond 15 working days, we will put a hold to the project and release the resources.\n` +
    `- The cost of any third-party tools, stock images, and licenses, if required, has not been included in our proposal.\n` +
    `- There is a possibility of Change of Estimation after Milestone 1 and if our Architects find a gap in requirements or new requirements that arise during the finalization phase. This will be done on mutual agreement.\n` +
    `- Client will be responsible for providing timely feedback within 7 working days.\n` +
    `- Any government taxes like GST etc will be applicable according to norms.`
  );
  
  const [createDraft, {isLoading}]= useEditDraftMutation()
  const [save, setSaved]= useState('Save')

  const [TotalPayable, setPayable]= useState("INR")
  const handlePayableChange = (e) => setPayable(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      
    
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createDraft({
        investmentData: {
          textContent,
          tableContent,
          TotalPayable,
          termsAndConditions   
        }, proposalId
      }).unwrap();
      console.log('Draft created successfully', response);
      setSaved('Saved!')
      window.location.reload()
    } catch (error) {
      console.error('Error creating draft:', error);
    }
  };
 

  const handleTermsChange = (event) => {
    setTermsAndConditions(event.target.value);
  };

  useEffect(() => {
    if (draft) {
      console.log("draft:", draft);

      if (draft.length > 0 && draft[0].investmentData) {
        // Access the clientName property only if investmentData exists
        setTextContent(draft[0].investmentData.textContent);
        const tableContentCopy = JSON.parse(JSON.stringify(draft[0].investmentData.tableContent));
        setTableContent(tableContentCopy);
        setPayable(draft[0].investmentData.TotalPayable)
        setTermsAndConditions(draft[0].investmentData.termsAndConditions)
      }
    }
  }, [draft]);
  const handleConvertToPdf = async () => {
    const pdf = new jsPDF();

    const htmlContent = document.getElementById('investment-pdf-content');
    const canvas = await html2canvas(htmlContent, { scrollY: -window.scrollY });
    const imageData = canvas.toDataURL('image/png');
    pdf.addImage(imageData, 'PNG', 10, 10, 190, 277); // Adjust the positioning and size

    pdf.save('investment.pdf');
    
  };
  
  return (
    <div id="investment-pdf-content" className={`${slideAnimation} h-[900px] flex flex-col lg:flex-row items-stretch`}>
      <div className="flex justify-center">
      <button
        className="bg-blue-300 text-white font-semibold py-2 px-4 rounded hover:bg-green-600 hover:text-white transition-colors duration-300"
        onClick={handleSubmit}
        disabled={save === 'Saved!'}
      >
       {save}
      </button>
    </div>
      <div className="lg:w-1/2 bg-white p-4 md:p-8">
        <h2 className="text-2xl text-black font-bold">Development Commercials</h2>
        <p className="text-sm mt-4" contentEditable onBlur={handleTextContentChange}>
          {textContent}
        </p>
        <div className="w-full mt-4 overflow-auto">
        <table className="w-full">
            <thead className="bg-black text-white h-8 text-left">
              <tr>
                <th className="w-[50%] px-2 border-solid border-2 border-black">TOTAL PAYABLE</th>   
                <th className="w-[50%] px-2 bg-white text-black border-solid border-2 border-black font-normal

                "><span >
                        
                        <input
                          type="text"
                          value={TotalPayable}
                          onChange= {handlePayableChange}
                        />
                      </span></th>
               
                <th></th>
              </tr>
            </thead>
          </table>
          <br/>
          <h2 className="text-1xl text-black font-bold">Payment Schedule</h2>
          
          <table className="w-full">
            <thead className="bg-black text-white h-8 text-left">
              <tr>
                <th className="w-[40%] px-2">Milestone</th>   
                <th className="w-[35%] px-2">% in total project</th>
                <th className="w-[25%] px-2">Total Payable</th>
                <th></th>
              </tr>
            </thead>

            <tbody className="text-sm">
              {tableContent.map((row, index) => (
                <tr key={index} className="h-8 even:bg-gray-200">
                  <td className="px-2 truncate">  
                      
                      <span >
                        
                        <input
                          type="text"
                          value={row.description}
                          onChange={(e) => handleTableContentChange(e, index, "description")}
                        />
                      </span>
                     
                  </td>
                  
                  <td className="px-2 truncate">
                    
                    <input
                      type="text"
                      value={row.price}
                      onChange={(e) => handleTableContentChange(e, index, "price")}
                    />
                  </td>
                  <td className="px-2 truncate">
                    
                    <input
                      type="text"
                      value={row.subtotal}
                      onChange={(e) => handleTableContentChange(e, index, "subtotal")}
                    />
                  </td>
                  <td className="px-4">
                    <button onClick={() => removeRow(index)} class="hover:text-red-600"><span class="material-symbols-outlined text-lg">delete
                    </span></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex justify-end mt-4">
          <button onClick={addNewRow} className="mr-2 text-green hover:text-green-600">
            <span className="material-symbols-outlined text-lg font-semibold ">add_circle</span>
          </button>
        </div>
        </div>
        <h2 className="text-2xl text-black font-bold">Terms & Conditions</h2>
        <div class="py-2 px-0">
          <ul class="list-none pl-4 space-y-2">
          <textarea
        value={termsAndConditions}
        onChange={handleTermsChange}
        rows={14}
        cols={70}
        className="py-2 px-2 text-sm"
      />
          </ul>
        </div>

      </div>
      <div className="lg:w-1/2 h-full min-h-96 bg-[url('/public/assets/images/backgroundImage.jpg')] bg-cover bg-no-repeat">
        <h1 className="p-4 md:w-[70%] mx-auto pt-16 md:pt-40 text-white text-3xl text-center">
          We look forward to getting started and to work on your high-quality production
        </h1>
      </div>
      
    </div>
  );
};

export default Investment;
