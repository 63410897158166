import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useEditProposalMutation } from '../../../features/proposals/ProposalsApiSlice';
import { useGetProposalsQuery } from '../../../features/proposals/ProposalsApiSlice';
import { selectProposalsByProposalId } from '../../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { handleConvertToPdf } from '../../../global';
const Cover = ({slideAnimation}) => {
  
  const [proposalTitle, setProposalTitle] = useState('Proposal E-Learning');
  const [proposalDate, setProposalDate] = useState('Jul 28th 2023');
  const [fromAddress, setFromAddress] = useState({
    name: 'Daisy Roberts',
    street: 'Director - Global Sales',
    postalCode: 'A1B, Sector 16',
    city: 'Noida. UP - 201301',
    country: 'India',
  });
  const { id } = useParams();

  const proposalId = id
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  console.log(id)

  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    console.log(proposal)
  }


  const [toAddress, setToAddress] = useState({
    name: 'Example Company',
    street: 'Empire street 123',
    postalCode: '254512',
    city: 'New York',
    country: 'USA',
  });
  const [digitalProposal, setDigitalProposal] = useState(
    `After thorough analysis of your unique requirements and objectives, we have meticulously crafted a business proposal that aligns perfectly with your vision. Our proposal encapsulates innovative strategies, actionable insights, and a roadmap to drive growth and elevate your market presence. Request to please go through and leave your questions if any. Thanks!
    `
  );
  const [validity, setValidity] = useState('Please note that this proposal is valid till Sep 26th 2023.');
  const [createProposal, {isLoading}]= useEditProposalMutation()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let coverData = {
          proposalTitle,
          proposalDate,
          fromAddress,
          toAddress,
          digitalProposal,
          validity,
        }
      
      await createProposal({proposalId, coverData}).unwrap();
      console.log('Proposal created successfully');
    } catch (error) {
      console.error('Error creating proposal:', error);
    }
  };

  // Function to handle beforeunload event
  const handleBeforeUnload = (e) => {
    // Call the handleSubmit function here
    handleSubmit(e);
    // Clear the prompt message (modern browsers ignore the text)
    delete e.returnValue;
  };

  /* useEffect(() => {
    // Add the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); */
  const [clientHello, setHello] = useState('Namasthe')
  const[callOnce, setOnce]= useState(true)
  useEffect(() => {
    if (proposal) {
      console.log("proposal:", proposal);
      if(proposal.length > 0 && proposal[0].clients_name){
        console.log("proposal:", proposal);
        setHello(`Namasthe ${proposal[0].clients_name}!`)
        }
      if (proposal.length > 0 && proposal[0].coverData) {
        // Access the clientName property only if introductionData exists
        setProposalTitle(proposal[0].coverData.proposalTitle);
        setProposalDate(proposal[0].coverData.proposalDate);
        setFromAddress(proposal[0].coverData.fromAddress);
        setToAddress(proposal[0].coverData.toAddress);
        setDigitalProposal(proposal[0].coverData.digitalProposal);
        setValidity(proposal[0].coverData.validity);
        
      }
    }
  }, [proposal]);

  
  useEffect (()=>{
    if (proposal) {
      //console.log("proposal:", proposal);
      if(proposal.length > 0 ){
        if(proposal[0].coverData.proposalTitle===proposalTitle){
          handleConvertToPdf('cover-pdf-content');
       }
    }
    }
  },[proposal, proposalTitle])
  
  return (
    <div id='cover-pdf-content' className={`${slideAnimation}`}>
      <div className={styles.imgContainer}>
        <div className="w-[90%] md:w-96 md:ml-20 mx-auto bg-gray-500 bg-opacity-80 p-8 text-white">
          <h1
            className="text-3xl font-bold"
           
            onBlur={(e) => setProposalTitle(e.target.textContent)}
            
          >
            {proposalTitle}
          </h1>
          <p
            className="text-sm mt-4"
           
            onBlur={(e) => setProposalDate(e.target.textContent)}
            
          >
            {proposalDate}
          </p>
        </div>
      </div>
      <div className={styles.textContainer}>
        <div className="md:w-[20%]  pt-2 px-6">
          <h3 className="font-semibold text-lg">From:</h3>
          <div className="text-sm">
            <p
             
              onBlur={(e) => setFromAddress({ ...fromAddress, name: e.target.textContent })}
              
            >
              {fromAddress.name}
            </p>
            <p
             
              onBlur={(e) => setFromAddress({ ...fromAddress, street: e.target.textContent })}
              
            >
              {fromAddress.street}
            </p>
            <p
             
              onBlur={(e) => setFromAddress({ ...fromAddress, postalCode: e.target.textContent })}
              
            >
              {fromAddress.postalCode}
            </p>
            <p
             
              onBlur={(e) => setFromAddress({ ...fromAddress, city: e.target.textContent })}
              
            >
              {fromAddress.city}
            </p>
            <p
             
              onBlur={(e) => setFromAddress({ ...fromAddress, country: e.target.textContent })}
              
            >
              {fromAddress.country}
            </p>
          </div>
        </div>
        <div className="md:w-[20%]  pt-2 px-6">
          <h3 className="font-semibold text-lg">Attn:</h3>
          <div className="text-sm">
            <p
             
              onBlur={(e) => setToAddress({ ...toAddress, name: e.target.textContent })}
              
            >
              {toAddress.name}
            </p>
            <p
             
              onBlur={(e) => setToAddress({ ...toAddress, street: e.target.textContent })}
              
            >
              {toAddress.street}
            </p>
            <p
             
              onBlur={(e) => setToAddress({ ...toAddress, postalCode: e.target.textContent })}
              
            >
              {toAddress.postalCode}
            </p>
            <p
             
              onBlur={(e) => setToAddress({ ...toAddress, city: e.target.textContent })}
              
            >
              {toAddress.city}
            </p>
            <p
             
              onBlur={(e) => setToAddress({ ...toAddress, country: e.target.textContent })}
              
            >
              {toAddress.country}
            </p>
          </div>
        </div>
        <div className="md:w-[60%]  pt-2 px-6">
          <h3 className="font-semibold text-lg">{clientHello}</h3>
          <p
            className="text-sm"
           
            onBlur={(e) => setDigitalProposal(e.target.textContent)}
            
          >
            {digitalProposal}
          </p>
        </div>
      </div>
      <div>
      
      </div>
      
      <div className="h-20">
        <p
          className="p-2 bg-black text-white text-left text-sm"
          style={{ paddingLeft: '20px', paddingRight: '10px' }}
          onBlur={(e) => setValidity(e.target.textContent)}
          
        >
          {validity}
        </p>
        
      </div>
     
    </div>
  );
};

const styles = {
  imgContainer:
    "flex justify-start items-center h-[450px] overflow-hidden relative bg-[url('/public/assets/images/backgroundImage.jpg')] bg-no-repeat bg-cover",
  textContainer: "flex flex-col md:flex-row min-h-72 bg-white py-8",
};

export default Cover;
