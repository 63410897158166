import { useNavigate } from 'react-router-dom'
import Loader from '../Loader';
import { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../features/auth/authApiSlice';
import { setCredentials } from '../../features/auth/authSlice';
import { setAdminDetails } from '../../global';

const LoginCard = () => {
  const navigate = useNavigate()
  const [login, { isLoading }] = useLoginMutation()
  
  const userRef = useRef()
  const errRef = useRef()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const dispatch = useDispatch()

 

  useEffect(()=>{
    userRef.current.focus()
  }, [])

  useEffect(()=>{
    setErrMsg('');
  }, [email, password])

  const handleSubmit = async (e)=>{
     e.preventDefault()
     try{
        const { accessToken, Name, Role } = await login({ email, password}).unwrap()
        dispatch(setCredentials({ accessToken }))
        console.log(Name, Role)
        setEmail('')
        setPassword('')
        setAdminDetails(Name, Role)
        if(Role=='user')
          navigate('/approvals')
        if(Role=='admin')
          navigate('/proposals')
     }catch(err) {
       if(!err.status){
          setErrMsg('No Server Response')

       }else if(err.status === 400){
          setErrMsg('Missing Email or Password')
       } else if(err.status === 401){
          setErrMsg('Unauthorized')
       } 
       else if(err.status === 402){
        setErrMsg('Incorrect Password')
      } else{
          setErrMsg(err.data?.message);
       }
       errRef.current.focus();
     }
  }
  const handleEmailInput =(e) => setEmail(e.target.value)
  const handlePwdInput= (e) => setPassword(e.target.value)

  const errClass = errMsg ? "errmsg" : "offscreen"

  if(isLoading) return  <Loader />
  const onSignupClick = () =>{
    navigate('/signup')
  }
  const onForgotPasswordClick = () =>{
    navigate('/forgot-password')
  }

  return (
    <div className="py-10 px-6 h-fit bg-white w-[95%] sm:w-[75%] lg:max-w-[400px] m-aut rounded-[2rem] ">
      <h1 className="mb-6 uppercase font-semibold text-4xl text-center">
        Login
      </h1>
      <p ref={errRef} className="mb-2" aria-live="assertive">{errMsg}</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-2">
          <label className="font-semibold inline-block mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="text"
            id="email"
            placeholder="Enter your email"
            className="bg-gray-100 h-10 w-full border-2 border-gray-300 rounded-lg px-4 focus:outline-none"
            ref={userRef}
            value= {email}
            onChange={handleEmailInput}
          />
        </div>

        <div className="mb-2">
          <label className="font-semibold inline-block mb-2" htmlFor="password">
            Password
          </label>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            className="bg-gray-100 h-10 w-full border-2 border-gray-300 rounded-lg px-4 focus:outline-none"
            ref={userRef}
            value={password}
            onChange={handlePwdInput}
          />
        </div>
        <div className="mb-6 text-end text-sm">
          <a className="text-red-400 font-semibold cursor-pointer uppercase hover:text-red-600 transition-colors duration-300" onClick={onForgotPasswordClick}>
            Forgot your password ?
          </a>
        </div>

        <div className="text-center" >
          <button className="py-2 px-8 rounded-full text-lg font-semibold text-white bg-black uppercase hover:bg-green-600 transition-colors duration-300"
          type='submit'>
            Login
          </button>
        </div>
      </form>

      <div className="text-center">
        
      </div>
    </div>
  );
};

export default LoginCard;
