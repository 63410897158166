import { useRef } from "react";
import SignaturePad from "react-signature-canvas";
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useEditProposalMutation } from "../../../features/proposals/ProposalsApiSlice";
import { useGetProposalsQuery } from '../../../features/proposals/ProposalsApiSlice';
import { selectProposalsByProposalId } from '../../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';


const AcceptProposal = ({ onSave, onClose }) => {
  const signatureRef = useRef({});
  const userRef = useRef();
  const [company, setCompany]= useState('')
  const [Name, setName]= useState('')
  const [signature, setSignature]= useState()
  const { id } = useParams();
  const [date, setDate] = useState()
  
  const proposalId = id
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content
  const[isSaved, setSaved] = useState('Accept & Send')

  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  console.log(id)

  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    //console.log(proposal)
  }
  const [createProposal, {isLoading}]= useEditProposalMutation()
  const [status, setStatus] = useState('Accepted!')
  const [isSend, setSend] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createProposal({
        acceptData: {
          Name,
          signature,
          company,
          date,
        }, proposalId, status
      }).unwrap();
      console.log('Proposal edited successfully', response);
      setSaved('Saved!')
      setSend(true)
    } catch (error) {
      console.error('Error creating proposal:', error);
    }
  };
  const [clinet, setNaswe]= useState("Client Name")
  
  useEffect(() => {
    setDate(new Date().toLocaleString())
    if (proposal) {
      //console.log("proposal:", proposal);
      if(proposal.length > 0){
        setNaswe(proposal[0].clients_name)
      }
      if (proposal.length > 0 && proposal[0].coverData) {
        // Access the clientName property only if investmentData exists
        //setTextContent(proposal[0].investmentData.textContent);
        //setTableContent(proposal[0].investmentData.tableContent);
        setCompany(proposal[0].coverData.toAddress.name)
        if(proposal[0].acceptData){
          setSaved('Saved!')
          setSend(true)
          setName(proposal[0].acceptData.Name)
          setSignature(proposal[0].acceptData.signature)
          setDate(proposal[0].acceptData.date)
        }
      }
    }
  }, [proposal]);

  const handleNameChange = (e) => setName(e.target.value)
  const handleSignatureChange = async () => {
    try {
      const canvas = signatureRef.current.getCanvas();
  
      if (!canvas) {
        // SignaturePad canvas not available yet
        return;
      }
  
      // Convert the signature to a blob
      const signatureBlob = await new Promise((resolve) => {
        canvas.toBlob((blob) => resolve(blob));
      });
  
      // You can do further processing with the signatureBlob if needed
      // For example, you might want to upload it to a server or save it in a state variable
  
      // Set the signature state to the blob URL
      setSignature(URL.createObjectURL(signatureBlob));
      
    } catch (error) {
      console.error('Error processing signature:', error);
    }
  };
  const onClear = () => signatureRef.current.clear();

  const disableScroll = () => {
    const body = document.body;
    const scrollBarWidth = window.innerWidth - body.clientWidth;

    body.style.overflow = 'hidden';
    body.style.marginRight = `${scrollBarWidth}px`;
  };

  
  return (
    <div className="  lg:w-[900px] ml-[-0.5rem] p-4 md:p-8 rounded-2xl bg-white">
      {!isSend?(
          <>
          <h1 className="mb-4 text-2xl font-semibold">Proposal Acceptance</h1>
      <p className="mb-4">
        {disableScroll}
        Awesome! Embrace the Power of Thought. Accept, and Let's Embark on a Journey of Success
      </p>

      <div className="flex flex-col md:flex-row mb-2 hidden">
        <p className="md:w-1/4">Company Name:</p>
        <strong>{company}</strong>
      </div>
      <div className="flex flex-col md:flex-row mb-2">
        <p className="md:w-1/4">
          Name<span className="text-red-600">*</span>:
        </p>
        <strong>{clinet}</strong>
      </div>

      <div className="flex flex-col md:flex-row mb-2">
        <p className="md:w-1/4">Date:</p>
        <strong>{date}</strong>
      </div>

      

      <div className="flex flex-col md:flex-row mb-2">
        <p className="md:w-1/4">
          Signature<span className="text-red-600">*</span>:
        </p>

        {/* 
        Refer given link for changings
        https://dev.to/ma7eer/create-a-signature-pad-in-react-3mmi 
        */}

        <SignaturePad
          ref={signatureRef}
          canvasProps={{
            className:
              "border-b-2 border-gray-400 bg-gray-100 h-[150px] w-full md:w-[60%]",
          }}
          value={signature}
          onChange= {handleSignatureChange}
          disabled={ isSaved === 'Saved!'}
        />
      </div>
      <div className="flex flex-col md:flex-row mb-2">
        <p className="md:w-1/4"></p>
        <div className="w-full md:w-[70%]">
          <button className="text-red-600 text-sm" onClick={onClear}  disabled={ isSaved === 'Saved!'}>
            Clear field
          </button>

          <p className="text-justifyb mt-2">
            <label className="text-sm">
              
            By clicking on “Accept & Send”, you agree to all the applicable terms and conditions specified in the proposal.
            </label>
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row mb-2">
        <p className="md:w-1/4"></p>
        <div className="flex gap-2">
        <button
         className="px-6 h-[36px] bg-black text-white rounded-3xl hover:bg-green-600 transition-colors duration-300"
         onClick={handleSubmit}
         disabled={ isSaved === 'Saved!'}
        >
          {isSaved}
        </button>
          <button
            className="px-4 h-[36px] bg-gray-200 rounded-3xl text-gray-500 hover:bg-red-600 hover:text-white transition-colors duration-300"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
          </>
      ) : (
        <>
        <div className="flex justify-end">
      <button className="px-2 py-0 hover:text-red-600" onClick={onClose}>
        <span className="material-symbols-outlined">close</span>
      </button>
    </div>
        <h1 className="mb-4 text-2xl font-semibold">Proposal Acceptance</h1>
        
        <p className="text-justifyb mt-2">
            <label className="text-sm">
              
            “Thanks, for your acceptance. A project manager will be allocated on the project and will be informed shortly.”

            </label>
          </p>
          
        </>
      )
      }
    </div>
  );
};

export default AcceptProposal;
