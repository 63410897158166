import React, { useState, useEffect } from 'react';
import { useEditProposalMutation } from '../../../features/proposals/ProposalsApiSlice';
import { useParams } from 'react-router-dom';
import { selectProposalsByProposalId } from '../../../features/proposals/ProposalsApiSlice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useGetProposalsQuery } from '../../../features/proposals/ProposalsApiSlice';

const Reviews = ({slideAnimation}) => {
  const { id } = useParams();
  const {
    data: proposals,
    isSuccess,
    isError,
    error
  } = useGetProposalsQuery()
  let content


  if(isError) {
         content = <p className={isError? "errmsg": "offscreen"}> {error?.data?.message}</p>
    }
  if(isSuccess){
      const { ids } = proposals
    
  }
  console.log(id)
  const proposalId = id
  const proposal = useSelector((state) => selectProposalsByProposalId(state, proposalId));

  if(proposal){
    console.log(proposal)
  }

  const [title, setTitle] = useState('Reviews');
  const [generalDescription, setGeneralDescription] = useState(
    'It\'s with gratitude that we acknowledge our clients and their repeated patronage. The companies you know and love share their working experiences about Alphabet Factory'
  );

  const [review1Company, setReview1Company] = useState(' Skilled Box');
  const [review1Text, setReview1Text] = useState(
    '“Our courses version changes were completed in a very professional manner. The Alphabet Factory\'s maintenance team is to be commended. All relevant terminology was perfectly included into the text without appearing forced, and the work was outstanding. They are such content masters; therefore, we want to work with them more.”'
  );
  const [review1Author, setReview1Author] = useState('Prof Mahindra Nayak, SME. Skilled Box');

  const [review2Author, setReview2Author] = useState('César Gutiérrez Díaz, CEO. Sustensol');  
  const [review2Company, setReview2Company] = useState(' Sustensol');
  const [review2Text, setReview2Text] = useState(
    '“Prior to approaching Alphabet Factory, we believed we were doing the best we could, but after connecting with them, we realized that our subject matter experts (SMEs) could provide subject expertise, but we needed instructional designers to assist with curriculum framework. This has greatly increased the value of our courses.”'
  );
  const [review3Author, setReview3Author] = useState('Siddika Jessa, CEO. Widerlens');

  const [review3Company, setReview3Company] = useState(' Widerlens');
  const [review3Text, setReview3Text] = useState('“The Delivery techniques Kalyan and team use are simply amazing; they are just out of world. The eLearning courses created by Alphabet Factory have such a fresh appearance on each screen, which keeps our students engaged and most importantly our sales are new 3x. Thanks Guys!”');
  const [review4Author, setReview4Author] = useState('Manoj Manohar, CEO. Reality Solutions');
  const [review4Company, setReview4Company] = useState(' Reality Solutions  ');
  
  const [review4Text, setReview4Text] = useState(
    '“Aparna and team did for us in just 3 weeks flat what our content development partners were unable to do in 2 – 4 months. We took a 2-day training program with them and found our team members much more productive. Great ROI, Interesting concept!”'
  );
  
  const [createProposal, {isLoading}]= useEditProposalMutation()

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

     
      // Call the mutation function and pass all the data, including the image file backgroundImage, // Pass the image file as it is
      const response = await createProposal({
        reviewsData: {
          title,
          generalDescription,
          review1Author,
          review1Company,
          review1Text,
          review2Author,
          review2Company,
          review2Text,
          review3Author,
          review3Company,
          review3Text,
          review4Author,
          review4Company,
          review4Text,

        }, proposalId
      }).unwrap();
      console.log('Proposal created successfully', response);
    } catch (error) {
      console.error('Error creating proposal:', error);
    }
  };

  useEffect(() => {
    if (proposal) {
      console.log("proposal:", proposal);

      if (proposal.length > 0 && proposal[0].reviewsData) {
        // Access the title property only if reviewsData exists
        setTitle(proposal[0].reviewsData.title);
        setGeneralDescription(proposal[0].reviewsData.generalDescription);
        setReview1Author(proposal[0].reviewsData.review1Author);
        setReview1Company(proposal[0].reviewsData.review1Company);
        setReview1Text(proposal[0].reviewsData.review1Text);
        setReview2Author(proposal[0].reviewsData.review2Author);
        setReview2Company(proposal[0].reviewsData.review2Company);
        setReview2Text(proposal[0].reviewsData.review2Text);
        setReview3Author(proposal[0].reviewsData.review3Author);
        setReview3Company(proposal[0].reviewsData.review3Company);
        setReview3Text(proposal[0].reviewsData.review3Text);
        setReview4Author(proposal[0].reviewsData.review4Author);
        setReview4Company(proposal[0].reviewsData.review4Company);
        setReview4Text(proposal[0].reviewsData.review4Text);

      }
    }
  }, [proposal]);

  return (
    <div className={`${slideAnimation} min-h-[566px]`}>
      <div className="grid md:grid-cols-12 grid-rows-1">
         <div className="md:col-span-4 bg-gray-300 p-4 lg:p-6">
          <h1 className="text-3xl font-bold"  onBlur={(e) => setTitle(e.target.textContent)} >
            Customer Stories
          </h1>
          <p className="mt-4"  onBlur={(e) => setGeneralDescription(e.target.textContent)} >
            {generalDescription}
          </p>
        </div>
        <div className="md:col-span-8 grid md:grid-cols-2">
          <div className="bg-white text-left p-4 lg:p-8 min-h-[250px] border-b ">
            {/* <h1 className="text-2xl text-red-600"  onBlur={(e) => setReview1Company(e.target.textContent)} suppressWarning={true}>
              {review1Company}
            </h1> */}
            <p className="text-sm"  onBlur={(e) => setReview1Text(e.target.textContent)} suppressWarning={true}>
              {review1Text}
            </p>
            <br/>
            <h3 className=" font-semibold"  onBlur={(e) => setReview1Author(e.target.textContent)} suppressWarning={true}>
              {review1Author}
            </h3>
          </div>
          <div className="bg-white text-left p-4 lg:p-8 min-h-[250px] border-b ">
            {/* <h1 className="text-2xl text-red-600"  onBlur={(e) => setReview2Company(e.target.textContent)} suppressWarning={true}>
              {review2Company}
            </h1> */}
            <p className="text-sm"  onBlur={(e) => setReview2Text(e.target.textContent)} suppressWarning={true}>
              {review2Text}
            </p>
            <br/>
            <h3 className=" font-semibold"  onBlur={(e) => setReview2Author(e.target.textContent)} suppressWarning={true}>
              {review2Author}
            </h3>
          </div>
          <div className="bg-white text-left p-4 lg:p-8 min-h-[250px] border-b ">
            {/* <h1 className="text-2xl text-red-600"  onBlur={(e) => setReview3Company(e.target.textContent)} suppressWarning={true}>
              {review3Company}
            </h1> */}
            <p className="text-sm"  onBlur={(e) => setReview3Text(e.target.textContent)} suppressWarning={true}>
              {review3Text}
            </p>
            <br/>
            <h3 className=" font-semibold"  onBlur={(e) => setReview3Author(e.target.textContent)} suppressWarning={true}>
              {review3Author}
            </h3>
          </div>
          <div className="bg-white text-left p-4 lg:p-8 min-h-[250px] border-b ">
            {/* <h1 className="text-2xl text-red-600"  onBlur={(e) => setReview4Company(e.target.textContent)} suppressWarning={true}>
              {review4Company}
            </h1> */}
            <p className="text-sm"  onBlur={(e) => setReview4Text(e.target.textContent)} suppressWarning={true}>
              {review4Text}
            </p>
            <br/>
            <h3 className=" font-semibold"  onBlur={(e) => setReview4Author(e.target.textContent)} suppressWarning={true}>
              {review4Author}
            </h3>
          </div>
          
          
          
        </div>
      </div>
      
    </div>
  );
};

export default Reviews;
