import Searchbar from "../../searchbar";
import { useGetProposalsQuery } from "../../../features/proposals/ProposalsApiSlice";
import Proposal from "../../../features/proposals/Proposal";
import Loader from "../../Loader";
import { setProposalno } from "../../../global";
import { useEffect, useState } from "react";
import { useGetUsersQuery } from "../../../features/users/userApiSlice";


const ProposalTable = ({ onCraeteNew, idsSetter}) => {
  
  const {
    data: proposals,
    isLoading: isProposalsLoading,
    isSuccess: isProposalsSuccess,
    isError: isProposalsError,
    error: proposalsError
  } = useGetProposalsQuery();
  
  const {
    data: clients,
    isLoading: isClientsLoading,
    isSuccess: isClientsSuccess,
    isError: isClientsError,
    error: clientsError
  } = useGetUsersQuery();

  const [content, setContent]= useState('')
  const [email, setEmail]= useState('')  

  useEffect(() => {
    if(!email)
    {if (isProposalsLoading) setContent(<p>Loading.......</p>) 

    if(isProposalsError) {
           setContent( <p className={isProposalsError? "errmsg": "offscreen"}> {proposalsError?.data?.message}</p>)
      }
    if(isClientsSuccess){
        const { ids } = clients
        setIds(ids)
        idsSetter(ids)
      }
    if(isProposalsSuccess){
        setProposalno()
        const { ids } = proposals
        const tableContent = ids?.length ? [...ids].reverse().map(proposalId => <Proposal key={proposalId} userId={proposalId}/>) : null;
        setContent(
            <table className="w-full text-sm text-left" style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}>
                <thead>
                    <tr>
                      <th className="pb-4">Prop No.</th>
                      <th className="pb-4">Name</th>
                      <th className="pb-4">Email</th>
                      <th className="pb-4">Phone</th>
                      <th className="pb-4">GST</th>
                      <th className="pb-4">Sent on</th>
                      <th className="pb-4">Status</th>
                    </tr>
                </thead>
                <tbody className="text-left ]">
                    {tableContent}
                </tbody>
            </table>
        )
        
    }}
   
  }, [isProposalsError, isProposalsLoading, isProposalsSuccess, proposalsError, proposals, email, isClientsSuccess]);

  const [clientids, setIds]= useState()

  
  const onSearchClick = () => {
    setProposalno()
    
    const { ids } = proposals

    const tableContent = ids?.length?ids.map(proposalId => <Proposal key={proposalId} userId={proposalId} email={email}/>) : null
    setContent(
        <table className="w-full text-sm">
            <thead>
                <tr>
                  <th className="pb-4">Prop No.</th>
                  <th className="pb-4">Name</th>
                  <th className="pb-4">Email</th>
                  <th className="pb-4">Phone</th>
                  <th className="pb-4">GST</th>
                  <th className="pb-4">Sent on</th>
                </tr>
            </thead>
            <tbody className="text-center ]">
                {tableContent}
            </tbody>
        </table>
    )
  }
  
  const handleEmailInput= (e) => setEmail(e.target.value)
  
  
  return (
    <>
      <div className="mt-4 flex justify-between items-center mb-4 ">
        <h2 className="text-xl font-bold">Sent Proposals</h2>
        <div className="flex sm:flex-row justify-end sm:mb-4 gap-4">
          <div className="sm:block hidden">
          <div className="flex items-center rounded-3xl border-2 border-gray-300 bg-gray-200 w-full sm:w-72 overflow-hidden">
          <input
              type="search"
              className="px-2 w-4/5 h-8 bg-transparent focus:outline-none"
              placeholder="Search by email"
              value={email}
              onChange={handleEmailInput}
            />{" "}
          <button className="flex justify-center items-center w-1/5 h-8 text-gray-400 hover:text-black ">
            <span class="material-symbols-outlined" onClick={onSearchClick}>search</span>
          </button>
          </div>
          </div>
          <button
            className="py-2 px-4 rounded-full text-sm font-semibold text-white bg-black hover:bg-green-600 transition-colors duration-300"
            onClick={onCraeteNew}
            
          >
            Create New Proposal
          </button>
        </div>
      </div>
      <div className="sm:hidden block mb-4">
        <Searchbar />
      </div>
      <div className="overflow-auto">
       {content}
      </div>
      
    </>
  );
};

export default ProposalTable;
