import React from "react";

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="text-6xl font-bold text-gray-600 mb-4">404</div>
      <div className="text-xl text-gray-600 mb-8">Page Not Found</div>
      <button
        className="px-6 py-3 text-lg font-bold text-white bg-black rounded-full shadow-lg hover:bg-gray-800"
        onClick={() => window.history.back()}
      >
        Go Back
      </button>
    </div>
  );
};

export default NotFoundPage;
